import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import classNames from 'classnames';
import { ProviderTestConnectionButton } from '@modules/ai/ai-settings-form/common/provider-test-connection-button';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useIsFocused } from '@common/hooks/use-is-focused';
import styles from './provider-form-base.css';
export function ProviderFormBase(props) {
    const { label, children, provider, isDirty, isInvalid, className, actions, withTextConnection, ...rest } = props;
    const wrapperRef = useRef(null);
    const [focused] = useIsFocused(wrapperRef, !!isDirty);
    return (_jsxs("section", { className: classNames(styles.container, className, {
            [styles.focused]: focused
        }), ...assignTestId('provider-form', rest), ref: wrapperRef, children: [_jsxs("div", { className: styles.headerContainer, children: [_jsx("div", { className: styles.titleContainer, children: _jsx(H3, { children: label }) }), _jsxs("div", { className: styles.actionsContainer, children: [withTextConnection && (_jsx(ProviderTestConnectionButton, { provider: provider, disabled: isInvalid })), actions] })] }), children] }));
}
