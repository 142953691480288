import { jsx as _jsx } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import { useProfileHistoryDetailsQuery } from '@app/common/queries/profiles';
import { ParagraphLoader } from '@app/common/loaders/paragraph-loader';
import { PropertiesDiff } from '@app/pages/profiles/profile-history/cells/properties-diff';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './profile-history-item-details.css';
export function ProfileHistoryItemDetails({ itemId, profileId, ...rest }) {
    const { data, isLoading } = useProfileHistoryDetailsQuery(profileId, itemId);
    if (isLoading) {
        return (_jsx("div", { className: styles.detailsContainer, children: _jsx(ParagraphLoader, {}) }));
    }
    return (_jsx(Text, { info: true, className: styles.detailsContainer, ...assignTestId(rest), children: data && _jsx(PropertiesDiff, { data: data === null || data === void 0 ? void 0 : data.snapshot, prevData: data === null || data === void 0 ? void 0 : data.prevSnapshot }) }));
}
