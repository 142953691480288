import { useQuery } from '@tanstack/react-query';
import { fetchPluginsByIds, fetchPluginsByPluginName } from '@api/marketplace';
import { formatApiError } from '@api/errors';
import auth from '@common/auth/auth';
import { getQueryAlertMessages } from '../formatters/alerts';
const keys = {
    all: ['marketplace-plugins'],
    list: (xmlIds) => ['marketplace-plugins', 'list', xmlIds],
    filtered: (filter) => ['marketplace-plugins', 'filtered', filter]
};
export function useOriginalMarketplacePlugins(xmlIds, enabled) {
    var _a;
    return useQuery({
        queryKey: keys.list(xmlIds),
        queryFn: () => fetchPluginsByIds(xmlIds),
        enabled: ((_a = auth.getUserProfile()) === null || _a === void 0 ? void 0 : _a.role) === 'ADMIN' && enabled,
        placeholderData: [],
        staleTime: Infinity,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load marketplace plugins'))
    });
}
export function useOriginalFilteredMarketplacePlugins(filter, enabled = true) {
    return useQuery({
        queryKey: keys.filtered(filter),
        queryFn: () => fetchPluginsByPluginName(filter),
        enabled,
        placeholderData: [],
        staleTime: Infinity,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load filtered marketplace plugins'))
    });
}
