import z from 'zod';
export const tHuggingFaceModelSchema = z.object({
    id: z.string(),
    modelName: z.string(), // hardcoded
    url: z.string().url('Url is not valid'),
    token: z.string().min(5, 'API Token is not valid').nullish()
});
export const tHuggingFaceAiFormSchema = z.object({
    id: z.string(),
    models: z
        .array(tHuggingFaceModelSchema)
        .min(1, 'Configure at least one model to complete the setup')
});
