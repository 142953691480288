import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { ConstituentDialog } from '@modules/constituents/constituent-dialog';
import { DefaultProfileSelector } from '@modules/ai/ai-settings-form/migration-dialog/default-profile-selector/default-profile-selector';
import { editableLLMProviders, useProviderInfos, useUpdateAiConfigurationMutation } from '@common/queries/ai-enterprise';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { MigrationStrategySelector } from './migration-strategy-selector/migration-strategy-selector';
import styles from './migration-dialog.css';
export function MigrationDialog(props) {
    const { previousState, stateUpdate, onCloseAttempt } = props;
    const [strategyPerProvider, setStrategyPerProvider] = useState({});
    const availableProvidersToMigrate = useMemo(() => editableLLMProviders(stateUpdate.provider).map(provider => provider.id), [stateUpdate]);
    const providersToRemove = useMemo(() => {
        const updatedProviders = editableLLMProviders(stateUpdate.provider).map(p => p.id);
        return editableLLMProviders(previousState.provider)
            .map(provider => provider.id)
            .filter(provider => !updatedProviders.includes(provider));
    }, [previousState, stateUpdate]);
    const defaultProviderAbsent = useMemo(() => {
        return !stateUpdate.defaultProvider || !stateUpdate.provider[stateUpdate.defaultProvider];
    }, [stateUpdate]);
    const [defaultProvider, setDefaultProvider] = useState(() => {
        return defaultProviderAbsent ? undefined : stateUpdate.defaultProvider;
    });
    const providerInfos = useProviderInfos(providersToRemove);
    const providersToMigrate = useMemo(() => providerInfos
        .map(infoResponse => infoResponse.data)
        .filter((data) => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.activeIdeProfilesCount) !== null && _a !== void 0 ? _a : 0) > 0; }), [providerInfos]);
    const validStrategies = useMemo(() => {
        if (providersToMigrate.some(data => !(strategyPerProvider === null || strategyPerProvider === void 0 ? void 0 : strategyPerProvider[data.provider]))) {
            return [];
        }
        const strategies = providersToRemove.map(provider => {
            if (providersToMigrate.find(providerToMigrate => providerToMigrate.provider === provider)) {
                return strategyPerProvider[provider];
            }
            return { type: 'disable', provider };
        });
        if (strategies.every(isValidStrategy)) {
            return strategies;
        }
        return [];
    }, [strategyPerProvider, providersToRemove, providersToMigrate]);
    const { mutateAsync: updateConfiguration, isLoading: aiSettingsUpdating } = useUpdateAiConfigurationMutation({ ...stateUpdate, defaultProvider }, validStrategies);
    const handleSaveChanges = useCallback(async () => {
        try {
            const updatedState = await updateConfiguration();
            onCloseAttempt(updatedState);
        }
        catch (e) {
            onCloseAttempt(undefined);
        }
    }, [updateConfiguration, onCloseAttempt]);
    const handleStrategyChange = useCallback((provider, strategy) => {
        setStrategyPerProvider(prev => ({ ...prev, [provider]: strategy }));
    }, [setStrategyPerProvider]);
    const isLoading = providerInfos.some(({ isLoading }) => isLoading) || aiSettingsUpdating;
    const canSave = (providersToRemove.length === 0 || validStrategies.length) && !isLoading && !!defaultProvider;
    return (_jsxs(ConstituentDialog, { title: "Save AI Settings", children: [_jsx(Content, { className: styles.container, children: _jsxs("section", { children: [!defaultProviderAbsent && providersToMigrate.length === 0 && (_jsx("p", { className: styles.message, children: "Saving your AI settings will overwrite the previous configuration" })), defaultProviderAbsent && (_jsx(DefaultProfileSelector, { availableProviders: availableProvidersToMigrate, defaultProvider: defaultProvider, onDefaultProviderChange: setDefaultProvider, className: styles.defaultProviderSelector })), providersToMigrate.map(data => {
                            return (_jsx(MigrationStrategySelector, { providerFrom: data.provider, usageCount: data.activeIdeProfilesCount, availableProviders: availableProvidersToMigrate, onStrategyChange: handleStrategyChange, selectedStrategy: strategyPerProvider[data.provider], className: styles.migrationSelector }, data.provider));
                        })] }) }), _jsxs(Panel, { children: [_jsx(Button, { ...assignTestId('confirm-button'), onClick: handleSaveChanges, loader: isLoading, primary: true, disabled: !canSave, children: "Save" }), _jsx(Button, { ...assignTestId('cancel-button'), loader: isLoading, onClick: () => onCloseAttempt(undefined), children: "Cancel" })] })] }));
}
function isValidStrategy(strategy) {
    if (!strategy) {
        return false;
    }
    return (strategy.type === 'disable' ||
        (strategy.type === 'migrate' && strategy.targetProvider !== undefined));
}
