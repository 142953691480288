import { jsx as _jsx } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import RingUIBreadcrumbs from '@jetbrains/ring-ui/components/breadcrumbs/breadcrumbs';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import NavigationLink from '../navigation/navigation-link';
import styles from './breadcrumbs.css';
function BreadcrumbsPart({ title, href, className, ...restProps }) {
    return href ? (_jsx(NavigationLink, { href: href, className: className, enableIsActive: false, ...restProps, children: title })) : (_jsx(Text, { className: className, ...restProps, children: title }));
}
export function Breadcrumbs({ breadcrumbs }) {
    return (_jsx("div", { className: styles.breadcrumbContainer, children: _jsx(RingUIBreadcrumbs, { children: breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.map(({ title, className, href, ...rest }) => (_jsx(BreadcrumbsPart, { title: title, href: href, className: className, ...assignTestId('breadcrumb-part-nth', rest) }, title))) }) }));
}
