import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import { isNil } from 'lodash';
import { LimitAssigmentEditor } from '@modules/constituents/constituent-dialog';
import { HiddenLimit } from '@modules/constituents/constituent-dialog/hidden-limit';
import NavigationLink from '@common/navigation/navigation-link';
import { ExternalLinks } from '@common/constants/constants';
import styles from './idep-settings-dialog.css';
export function IdePSettingsDialogBase(props) {
    const { onCloseAttempt, isLoading, contentClassName, onActivate, initialLimitUsage, limitLockType } = props;
    const [usage, setUsage] = useState(initialLimitUsage !== null && initialLimitUsage !== void 0 ? initialLimitUsage : 'unlimited');
    const handleActivate = useCallback(() => {
        onActivate({
            assignedUsersLimit: usage === 'unlimited' ? undefined : usage
        });
    }, [onActivate, usage]);
    const activateDisabled = isNil(usage) || (usage !== 'unlimited' && usage < 1);
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    return (_jsxs(_Fragment, { children: [_jsx(Content, { className: contentClassName, children: _jsxs("div", { className: styles.contentWrapper, children: [_jsxs("p", { className: styles.info, children: ["With IDE Provisioner enabled, you can manage which tools, plugins,", _jsx("br", {}), "and settings are available to profile users.", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_IDE_PROVISIONER_DESCRIPTION, children: "Learn more." }), _jsx("br", {}), _jsxs(Text, { info: true, size: "s", children: ["JetBrains IDEs managed by IDE Provisioner use the IDE Services proxy", _jsx("br", {}), "to access the", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_MARKETPLACE, children: "JetBrains Marketplace" }), "."] })] }), limitLockType === 'hidden' && _jsx(HiddenLimit, { className: styles.limit }), limitLockType !== 'hidden' && (_jsx(LimitAssigmentEditor, { toggleText: "Unlimited usage", limitNotice: _jsxs(_Fragment, { children: ["You can control your spending by setting a limit on the number of assigned users.", _jsx("br", {}), "Users who exceed this limit will have restricted access."] }), limit: usage, readonly: limitLockType === 'readonly', onLimitChange: setUsage, className: styles.limit }))] }) }), _jsxs(Panel, { children: [_jsx(Button, { disabled: activateDisabled, loader: isLoading, onClick: handleActivate, primary: true, "data-test": "dialog-confirm-button", children: "Apply" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
