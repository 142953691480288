// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__c35 {
  display: flex;
  gap: calc(2 * var(--ring-unit));
  flex-direction: column;
}

.constituents__db7 {
  margin-left: calc(var(--ring-unit) * 4);
}

.constituentsTitle__aec {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);

  color: var(--ring-secondary-color);
}

.constituentsTitle__aec + .constituentList__b81 {
  margin-top: calc(var(--ring-unit) * 2);
}

.constituentsTitle__aec + .emptyConstituents__b70 {
  margin-top: calc(var(--ring-unit) * 2);
}

.emptyConstituents__b70 {
  color: var(--ring-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/modules/constituents/user-constituents/user-constituents.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,sBAAsB;AACxB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,wCAAwC;EACxC,2CAA2C;;EAE3C,kCAAkC;AACpC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".wrapper {\n  display: flex;\n  gap: calc(2 * var(--ring-unit));\n  flex-direction: column;\n}\n\n.constituents {\n  margin-left: calc(var(--ring-unit) * 4);\n}\n\n.constituentsTitle {\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n\n  color: var(--ring-secondary-color);\n}\n\n.constituentsTitle + .constituentList {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n\n.constituentsTitle + .emptyConstituents {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n\n.emptyConstituents {\n  color: var(--ring-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__c35`,
	"constituents": `constituents__db7`,
	"constituentsTitle": `constituentsTitle__aec`,
	"constituentList": `constituentList__b81`,
	"emptyConstituents": `emptyConstituents__b70`
};
export default ___CSS_LOADER_EXPORT___;
