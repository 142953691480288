export function toLicense(licenseTicketDto) {
    return licenseTicketDto.productTickets.map(productTicketDto => {
        return {
            id: productTicketDto.id,
            from: new Date(productTicketDto.dates.from),
            to: productTicketDto.dates.release ? new Date(productTicketDto.dates.release) : undefined,
            applicationName: productTicketDto.product.name,
            licenseName: licenseTicketDto.license.name,
            hostName: productTicketDto.hostname,
            ip: productTicketDto.ip
        };
    });
}
