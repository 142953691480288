import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { ConstituentDialog } from '@modules/constituents/constituent-dialog';
import { LvTrialContent } from '@modules/license-vault/common/dialogs/lv-enable-dialog/lv-trial-content';
import { LvEnableDialogBase } from '@modules/license-vault/common/dialogs/lv-enable-dialog/lv-enable-dialog-base';
import { ConstituentDialogSteps } from '@modules/constituents/constituent-dialog/constituent-dialog-steps';
import { getTrialMaxUsage } from '@modules/constituents/constituent-dialog/limit-assigment-editor/get-trial-max-usage';
import { useTbeLicense } from '@common/queries/tbe-license';
import { LicenseVaultLogo } from '../../license-vault-logo/license-vault-logo';
import styles from './lv-enable-dialog.css';
export function LvEnableDialog(props) {
    var _a;
    const { onCloseAttempt, startFromStep } = props;
    const [step, setStep] = useState(startFromStep);
    const { data: licenseData } = useTbeLicense();
    const constituentTrialData = (_a = licenseData === null || licenseData === void 0 ? void 0 : licenseData.constituents.find(c => c.id === 'lv')) === null || _a === void 0 ? void 0 : _a.trial;
    const maxUsage = getTrialMaxUsage(constituentTrialData);
    const showPostPaidHint = (constituentTrialData === null || constituentTrialData === void 0 ? void 0 : constituentTrialData.state) !== 'ACTIVE' && (licenseData === null || licenseData === void 0 ? void 0 : licenseData.plan) === 'POSTPAID';
    const handleStepChange = () => {
        setStep(prev => prev + 1);
    };
    return (_jsxs(ConstituentDialog, { title: "Enable License Vault", titleLogo: _jsx(LicenseVaultLogo, {}), children: [step === ConstituentDialogSteps.TRIAL && (_jsx(LvTrialContent, { constituentData: (constituentTrialData === null || constituentTrialData === void 0 ? void 0 : constituentTrialData.state) === 'AVAILABLE' ? constituentTrialData : undefined, onClose: () => onCloseAttempt(undefined), changeStep: handleStepChange, contentClassName: styles.contentWrapper })), step === ConstituentDialogSteps.FORM && (_jsx(LvEnableDialogBase, { onClose: () => onCloseAttempt(undefined), contentClassName: styles.contentWrapper, limit: maxUsage, showPostpaidHint: showPostPaidHint, ...props }))] }));
}
