// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formWrapper__dc1 {
  display: flex;
}

.formData__ef1 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 3);
}

.formFields__ca1 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/user-details-page/user-details-edit-page/user-details-edit-page.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC","sourcesContent":[".formWrapper {\n  display: flex;\n}\n\n.formData {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 3);\n}\n\n.formFields {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": `formWrapper__dc1`,
	"formData": `formData__ef1`,
	"formFields": `formFields__ca1`
};
export default ___CSS_LOADER_EXPORT___;
