import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import Icon from '@jetbrains/ring-ui/components/icon';
import arrowRight from '@jetbrains/icons/arrow-right';
import classNames from 'classnames';
import { AI_PROVIDERS } from '@modules/ai/common/constants';
import { formatPlural } from '@common/format-plural';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './migration-strategy-selector.css';
export function MigrationStrategySelector(props) {
    var _a;
    const { providerFrom, availableProviders, onStrategyChange, selectedStrategy, usageCount, className } = props;
    const handleStrategySelection = useCallback((strategy) => {
        if (strategy) {
            onStrategyChange(providerFrom, {
                provider: providerFrom,
                type: strategy === null || strategy === void 0 ? void 0 : strategy.key,
                targetProvider: undefined
            });
        }
    }, [onStrategyChange, providerFrom]);
    const handleNewProviderSelection = useCallback((provider) => {
        if (provider) {
            onStrategyChange(providerFrom, {
                provider: providerFrom,
                type: 'migrate',
                targetProvider: provider.key
            });
        }
    }, [onStrategyChange, providerFrom]);
    return (_jsxs("div", { className: classNames(styles.wrapper, className), ...assignTestId('migration-strategy-selector', providerFrom), children: [_jsxs("p", { className: styles.message, children: [(_a = AI_PROVIDERS[providerFrom]) === null || _a === void 0 ? void 0 : _a.label, " is currently enabled in", ' ', formatPlural(usageCount, {
                        one: 'one profile',
                        multiple: `${usageCount} profiles`
                    }), ". Choose a transfer option to continue."] }), _jsxs("div", { className: styles.selectorWrapper, children: [_jsx(Select, { selectedLabel: "Transfer option", data: MIGRATION_OPTIONS, selected: MIGRATION_OPTIONS.find(({ key }) => key === (selectedStrategy === null || selectedStrategy === void 0 ? void 0 : selectedStrategy.type)), onSelect: handleStrategySelection, className: styles.selector, ...assignTestId('migration-option-select') }), (selectedStrategy === null || selectedStrategy === void 0 ? void 0 : selectedStrategy.type) === 'migrate' && (_jsxs(_Fragment, { children: [_jsx(Icon, { glyph: arrowRight, className: styles.icon }), _jsx(Select, { selectedLabel: "AI provider", data: availableProviders.map(provider => ({
                                    ...AI_PROVIDERS[provider],
                                    ...assignTestId('migration-target-item', provider)
                                })), selected: selectedStrategy.targetProvider
                                    ? [AI_PROVIDERS[selectedStrategy.targetProvider]]
                                    : undefined, onSelect: handleNewProviderSelection, className: styles.selector, ...assignTestId('migration-target-select') })] }))] })] }));
}
const MIGRATION_OPTIONS = [
    {
        key: 'disable',
        label: 'Disable AI Enterprise for these users',
        ...assignTestId('migration-option-disable')
    },
    {
        key: 'migrate',
        label: 'Switch to another provider',
        ...assignTestId('migration-option-migrate')
    }
];
