import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown';
const YT_NEW_ISSUE_LINK = 'https://youtrack.jetbrains.com/newIssue?project=IDES';
export const AlertTimeouts = {
    DEFAULT: 5000,
    SHORT: 3000,
    LONG: 10000
};
export const TooltipDelays = {
    NORMAL: Dropdown.defaultProps.hoverShowTimeOut,
    LONG: 1000
};
export const ExternalLinks = {
    FEEDBACK: 'https://www.jetbrains.com/feedback/ide-services/',
    BUG_REPORT: YT_NEW_ISSUE_LINK,
    SUPPORT: 'https://ide-services-support.jetbrains.com',
    FEEDBACK_WITH_VM_OPTIONS_TEMPLATE: `${YT_NEW_ISSUE_LINK}&summary=VM%20Options%20is%20not%20allowed&description=%2F%2F%2F%20please%20specify%20what%20JVM%20options%20you%20are%20trying%20to%20specify`,
    TOOLBOX_APP: 'https://www.jetbrains.com/toolbox-app',
    AMAZON_S3: 'https://s3.region.amazonaws.com',
    MARKDOWN_SPEC: 'https://commonmark.org/help/',
    JB_MANAGING_PLUGINS: 'https://www.jetbrains.com/help/idea/managing-plugins.html#repos',
    JB_CONFIGURE_JVM: 'https://www.jetbrains.com/help/idea/tuning-the-ide.html#configure-jvm-options',
    JB_IDE_PROVISIONER_DESCRIPTION: 'https://www.jetbrains.com/help/ide-services/product-description.html#ide_provisioner',
    JB_PLUGINS: 'https://plugins.jetbrains.com',
    JB_SALES: 'mailto:ideservices@jetbrains.com',
    JB_AI_ENTERPRISE_DETAILED_DATA_COLLECTION: 'https://www.jetbrains.com/help/ai/data-collection-and-use-policy.html#detailed-data-collection',
    JB_MARKETPLACE: 'https://plugins.jetbrains.com/',
    JB_CWM: 'https://www.jetbrains.com/code-with-me/',
    JB_LV_ACTIVATION: 'https://jb.gg/ls-activate-ide',
    JB_ACCOUNT: 'https://account.jetbrains.com',
    JB_ACCOUNT_USAGE_STATS: 'https://account.jetbrains.com/license/usage',
    JB_CONTACT_SALES_FORM: 'https://ide-services-support.jetbrains.com/hc/en-us/requests/new',
    JB_MELLUM_INFO: 'https://blog.jetbrains.com/blog/2024/10/22/introducing-mellum-jetbrains-new-llm-built-for-developers/',
    TOOLBOX_APP_AUTH_URL: 'jetbrains://toolbox/enterprise/auth'
};
export const AppGlobal = {
    BUILD_NUMBER: WEBPACK_BUILD_INFO,
    COPYRIGHT: `Copyright © 2021–${new Date().getFullYear()} JetBrains`
};
