import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import TagsList from '@jetbrains/ring-ui/components/tags-list/tags-list';
import Text from '@jetbrains/ring-ui/components/text/text';
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown';
import Popup from '@jetbrains/ring-ui/components/popup/popup';
import { Directions } from '@jetbrains/ring-ui/components/popup/popup.consts';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { NestedCheckbox, getUpdatedSelection } from '@common/nested-checkbox';
import { EnumerableTextElements } from '@common/text-formattings/enumerable-text-elements';
import { getBuildsOptions } from '../../../../common/build-filters';
import { getBuildsListOptions } from './get-builds-list-options';
import styles from './build-field.css';
function BuildField({ allProducts }) {
    const { setValue, watch } = useFormContext();
    const products = watch('products');
    const builds = watch('builds');
    const buildOptions = useMemo(() => getBuildsOptions(allProducts, products !== null && products !== void 0 ? products : [], build => !build.match(/^\d{3}\./)).map(option => ({
        ...option,
        id: option.build,
        checked: (builds || []).some(it => it === option.build)
    })), [allProducts, products, builds]);
    const dataListOptions = useMemo(() => {
        return getBuildsListOptions(buildOptions).map(addSelectedFlag);
        function addSelectedFlag({ items, ...rest }) {
            return {
                items: items.map(addSelectedFlag),
                checked: (builds || []).some(it => it === rest.build),
                ...rest
            };
        }
    }, [buildOptions, builds]);
    const handleSelection = useCallback((buildKey) => {
        setValue('builds', getUpdatedSelection(buildKey, builds, dataListOptions, 'build'), {
            shouldDirty: true
        });
    }, [builds, dataListOptions, setValue]);
    const onRemoveTag = useCallback((tag) => (e) => {
        e.stopPropagation();
        if (typeof tag.key === 'string') {
            handleSelection(tag.key);
        }
    }, [handleSelection]);
    const dropdownAnchor = useCallback((anchorProps) => (_jsx("div", { ...anchorProps, className: styles.tagsContainer, children: !builds || !builds.length ? (_jsx(Text, { info: true, className: styles.placeholder, children: "All" })) : (_jsx(TagsList, { id: "buildsField", tags: builds.map(build => ({ key: build, label: build })), handleRemove: onRemoveTag })) })), [builds, onRemoveTag]);
    const renderCheckboxLabel = (build) => {
        return (_jsxs("div", { className: styles.titleContainer, children: [_jsx(Text, { children: build.build }), build.build.lastIndexOf('*') === -1 && (_jsx(Text, { info: true, size: "s", className: styles.checkboxSubtitle, children: build.label && (_jsx(EnumerableTextElements, { getKey: val => `build-${val}`, getValue: val => val, maxVisibleElems: 2, items: build.label })) }))] }));
    };
    return (_jsxs("div", { ...assignTestId('application-filter-build'), children: [_jsx("p", { className: styles.label, children: "Build" }), _jsx(Dropdown, { anchor: dropdownAnchor, className: styles.dropdownContainer, children: _jsx(Popup, { className: styles.popupContainer, directions: [Directions.BOTTOM_CENTER], children: dataListOptions.map(build => (_jsx(NestedCheckbox, { item: build, className: styles.checkboxSubtitle, onToggle: build => handleSelection(build.build), hasCheckedParent: false, renderLabel: renderCheckboxLabel, assignDataTest: item => item.build }))) }) })] }));
}
export default BuildField;
