import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouteLoaderData } from 'react-router-dom';
import Input from '@jetbrains/ring-ui/components/input/input';
import { UserConstituents } from '@modules/constituents/user-constituents/user-constituents';
import { ToolboxAppWidget } from '@modules/applications/widgets/toolbox-app-widget/toolbox-app-widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './regular-user-general-page.css';
export function RegularUserGeneralPage() {
    const user = useRouteLoaderData('user-details');
    return (_jsxs("div", { className: styles.container, children: [_jsxs("div", { className: styles.userDetailsContainer, children: [_jsxs("div", { className: styles.userInfoContainer, children: [_jsx(Input, { label: "Username", value: user.username, borderless: true, contentEditable: false, className: styles.input, ...assignTestId('regular-user-page-username') }), _jsx(Input, { label: "Email", value: user.email, borderless: true, contentEditable: false, className: styles.input, ...assignTestId('regular-user-page-email') })] }), _jsx(UserConstituents, { constituents: user.constituents })] }), _jsx(ToolboxAppWidget, {})] }));
}
