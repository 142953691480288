import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Button from '@jetbrains/ring-ui/components/button/button';
import refreshIcon from '@jetbrains/icons/update';
import { isEqual } from 'lodash';
import okIcon from '@jetbrains/icons/ok';
import activityIcon from '@jetbrains/icons/activity';
import failedIcon from '@jetbrains/icons/exception-empty';
import { ProviderTestConnectionResultDialog } from '@modules/ai/ai-settings-form/common/provider-test-connection-result-dialog';
import { ProviderTestConnectionResult } from '@modules/ai/ai-settings-form/common/provider-test-connection-result';
import { AI_PROVIDERS } from '@modules/ai/common/constants';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import iconLoaders from '@common/loaders/icon-loaders.css';
import { useTestProviderConnection } from '@common/queries/ai-enterprise';
import { useOpenModal } from '@common/modals/hooks';
import { PopupMessage } from '@common/popup-message/popup-message';
import styles from './provider-test-connection-button.css';
export function ProviderTestConnectionButton({ provider, disabled, ...rest }) {
    var _a, _b;
    const [lastTestedProviderState, setLastTestedProviderState] = useState();
    const providerTestMutation = useTestProviderConnection();
    const openResultDialog = useOpenModal(ProviderTestConnectionResultDialog, { provider, testResult: providerTestMutation.data || { status: 'Success', messages: [] } }, [provider, providerTestMutation.data]);
    const testConnection = useCallback(() => {
        providerTestMutation.mutate(provider, {
            onSuccess: result => {
                setLastTestedProviderState({ ...provider });
                if (result.status === 'Error') {
                    openResultDialog();
                }
            }
        });
    }, [openResultDialog, provider, providerTestMutation]);
    useEffect(() => {
        if (providerTestMutation.data && !isEqual(lastTestedProviderState, provider)) {
            providerTestMutation.reset();
        }
    }, [lastTestedProviderState, provider, providerTestMutation, providerTestMutation.data]);
    const testConnectionIcon = useMemo(() => {
        var _a, _b;
        if (((_a = providerTestMutation.data) === null || _a === void 0 ? void 0 : _a.status) === 'Success') {
            return okIcon;
        }
        if (((_b = providerTestMutation.data) === null || _b === void 0 ? void 0 : _b.status) === 'Error') {
            return failedIcon;
        }
        if (providerTestMutation.isLoading) {
            return refreshIcon;
        }
        return activityIcon;
    }, [(_a = providerTestMutation.data) === null || _a === void 0 ? void 0 : _a.status, providerTestMutation.isLoading]);
    const button = useMemo(() => {
        var _a, _b;
        return (_jsx(Button, { inline: true, onClick: testConnection, loader: providerTestMutation.isLoading, disabled: providerTestMutation.isLoading || disabled, className: classNames({
                [styles.button]: true,
                [iconLoaders.refreshIconLoader]: providerTestMutation.isLoading,
                [styles.iconActive]: !providerTestMutation.data && !disabled,
                [styles.iconSuccess]: ((_a = providerTestMutation.data) === null || _a === void 0 ? void 0 : _a.status) === 'Success',
                [styles.iconError]: ((_b = providerTestMutation.data) === null || _b === void 0 ? void 0 : _b.status) === 'Error'
            }), icon: testConnectionIcon, ...assignTestId('provider-test-connection-button', rest), children: "Test connection" }));
    }, [
        testConnection,
        providerTestMutation.isLoading,
        providerTestMutation.data,
        disabled,
        testConnectionIcon,
        rest
    ]);
    return providerTestMutation.data ? (_jsx(PopupMessage, { title: `${((_b = AI_PROVIDERS[provider.id]) === null || _b === void 0 ? void 0 : _b.label) || ''} Test Connection`, anchor: button, children: _jsx(ProviderTestConnectionResult, { testResult: providerTestMutation.data }) })) : (button);
}
