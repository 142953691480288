import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Checkbox from '@jetbrains/ring-ui/components/checkbox/checkbox';
import classnames from 'classnames';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './nested-checkbox.css';
export function NestedCheckbox(props) {
    const { item, onToggle: handleToggle, hasCheckedParent, renderLabel, className, assignDataTest } = props;
    const { checked, ...itemProps } = item;
    const isChecked = checked || hasCheckedParent;
    const handleSelection = useCallback(() => handleToggle(item), [item, handleToggle]);
    return (_jsxs("div", { className: styles.checkboxContainer, children: [_jsxs("div", { className: classnames(styles.titleContainer, className), children: [_jsx(Checkbox, { onChange: handleSelection, checked: item.disabled ? false : isChecked, ...itemProps, label: null, ...assignTestId(assignDataTest && assignDataTest(item)) }), renderLabel && renderLabel(item)] }), item.items.map(child => (_jsx(NestedCheckbox, { renderLabel: renderLabel, onToggle: handleToggle, hasCheckedParent: isChecked, item: child, assignDataTest: assignDataTest }, child.id)))] }));
}
