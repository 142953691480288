import z from 'zod';
const tBedrockAiModel = z.object({
    id: z.string(),
    modelName: z.string()
});
export const tBedrockAiFormSchema = z.object({
    id: z.string().optional(),
    region: z.string().nonempty('Region is required'),
    accessKey: z.string().min(5, 'Access Key is required').nullable(),
    secretKey: z.string().min(5, 'Secret Key is required').nullable(),
    models: z.array(tBedrockAiModel)
});
