// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleContainer__b18 {
  display: flex;
}

.checkboxContainer__b07 {
  display: flex;
  gap: var(--ring-unit);
  flex-direction: column;
  padding-left: calc(var(--ring-unit) * 2);
}
`, "",{"version":3,"sources":["webpack://./src/common/nested-checkbox/nested-checkbox.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,sBAAsB;EACtB,wCAAwC;AAC1C","sourcesContent":[".titleContainer {\n  display: flex;\n}\n\n.checkboxContainer {\n  display: flex;\n  gap: var(--ring-unit);\n  flex-direction: column;\n  padding-left: calc(var(--ring-unit) * 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": `titleContainer__b18`,
	"checkboxContainer": `checkboxContainer__b07`
};
export default ___CSS_LOADER_EXPORT___;
