import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { CodeWithMeLogo } from '@jetbrains/logos/react';
import { ConstituentDialog } from '@modules/constituents/constituent-dialog';
import { CwmEnableContent } from '@modules/code-with-me/common/dialogs/cwm-enable-dialog/cwm-enable-content';
import { CwmTrialContent } from '@modules/code-with-me/common/dialogs/cwm-enable-dialog/cwm-trial-content';
import { ConstituentDialogSteps } from '@modules/constituents/constituent-dialog/constituent-dialog-steps';
import { getLimitLockType } from '@modules/constituents/constituent-dialog/limit-assigment-editor/get-limit-lock-type';
import { useTbeLicense } from '@common/queries/tbe-license';
import styles from './cwm-enable-dialog.css';
export function CwmEnableDialog(props) {
    var _a;
    const { onCloseAttempt, startFromStep } = props;
    const [step, setStep] = useState(startFromStep);
    const { data: licenseData } = useTbeLicense();
    const constituentTrialData = (_a = licenseData === null || licenseData === void 0 ? void 0 : licenseData.constituents.find(c => c.id === 'cwm')) === null || _a === void 0 ? void 0 : _a.trial;
    const lockLimitType = getLimitLockType(licenseData === null || licenseData === void 0 ? void 0 : licenseData.plan, constituentTrialData === null || constituentTrialData === void 0 ? void 0 : constituentTrialData.state);
    const handleStepChange = () => {
        setStep(prev => prev + 1);
    };
    return (_jsxs(ConstituentDialog, { title: "Code With Me Enterprise", titleLogo: _jsx(CodeWithMeLogo, {}), children: [step === ConstituentDialogSteps.TRIAL && (_jsx(CwmTrialContent, { constituentData: (constituentTrialData === null || constituentTrialData === void 0 ? void 0 : constituentTrialData.state) === 'AVAILABLE' ? constituentTrialData : undefined, onClose: () => onCloseAttempt(undefined), changeStep: handleStepChange, contentClassName: styles.contentWrapper })), step === ConstituentDialogSteps.FORM && (_jsx(CwmEnableContent, { onClose: () => onCloseAttempt(undefined), contentClassName: styles.contentWrapper, inputSettings: (constituentTrialData === null || constituentTrialData === void 0 ? void 0 : constituentTrialData.state) === 'ACTIVE'
                    ? {
                        readonly: lockLimitType === 'readonly',
                        initialLimitUsage: constituentTrialData === null || constituentTrialData === void 0 ? void 0 : constituentTrialData.maxUsage
                    }
                    : undefined, ...props }))] }));
}
