import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { licenseVaultApi } from '@jetbrains-license-vault/client/lib';
import { Navigate, useParams } from 'react-router-dom';
import { LicencesTable, toLicense } from '@modules/license-vault/licences-table';
import auth from '@common/auth/auth';
export function UserLicensesPage() {
    var _a;
    const currentUserId = (_a = auth.getUserProfile()) === null || _a === void 0 ? void 0 : _a.id;
    const { id: userId } = useParams();
    const { data: licensesDto } = licenseVaultApi.users.useLicenses();
    const licenses = useMemo(() => {
        return licensesDto ? licensesDto.flatMap(toLicense) : [];
    }, [licensesDto]);
    if (currentUserId && userId && currentUserId !== userId && userId !== 'me') {
        return _jsx(Navigate, { to: `/users/${userId}` });
    }
    return (_jsx("div", { "data-test": "licenses-table", children: _jsx(LicencesTable, { licenses: licenses }) }));
}
