import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Icon from '@jetbrains/ring-ui/components/icon';
import classNames from 'classnames';
import { Badge } from '@common/badge';
import styles from './setting-layout.css';
export function SettingLayout({ children, selected, isChanged, icons, onDoubleClick, onTileClick, variant = 'bordered', className }) {
    const [isHovering, setIsHovering] = useState(false);
    const onIconClick = (e, onClick) => {
        e.stopPropagation();
        onClick();
    };
    return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    _jsxs("div", { className: classNames(styles.bodyContainer, className, {
            [styles.selected]: selected,
            [styles.nonInteractive]: !onTileClick && !onDoubleClick,
            [styles.variantBordered]: variant === 'bordered',
            [styles.variantFlat]: variant === 'flat'
        }), onDoubleClick: onDoubleClick, onClick: onTileClick, onMouseEnter: () => setIsHovering(true), onMouseLeave: () => setIsHovering(false), "data-test": "card-container", children: [_jsx("div", { className: styles.content, children: children }), isHovering && (_jsx("div", { className: styles.sideContainer, children: _jsx("div", { className: styles.iconsContainer, children: icons === null || icons === void 0 ? void 0 : icons.map(({ glyph, onClick, title }) => (_jsx(Icon, { glyph: glyph, title: title, onClick: e => onIconClick(e, onClick), className: styles.actionIcon, "data-test": "delete-button" }, glyph))) }) })), isChanged && (_jsx("div", { children: _jsx(Badge, { variant: Badge.variant.Filled, color: Badge.color.Disabled, size: Badge.size.Md, className: styles.badge, children: "Changed" }) }))] }));
}
