import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RingFieldsList from '@common/form/ring-fields-list';
import { BasicTableView } from '@common/tables/basic-table-view';
import { InformationBanner } from '@common/information-banner/information-banner';
import NavigationLink from '@common/navigation/navigation-link';
import { ExternalLinks } from '@common/constants/constants';
import styles from './mellum-ai-provider-form.css';
const columns = [
    {
        id: 'id',
        title: 'ID'
    },
    {
        id: 'url',
        title: 'Model URL'
    }
];
export function MellumAiProviderForm({ provider: { models } }) {
    return (_jsxs(RingFieldsList, { children: [_jsx(InformationBanner, { variant: "neutral", className: styles.mellumBanner, children: _jsxs("span", { children: ["JetBrains Mellum models extend the capabilities of a custom AI provider, improving code completion for various programming languages.", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_MELLUM_INFO, target: "_blank", children: "Learn more" })] }) }), _jsx("div", { children: _jsx(BasicTableView, { columns: columns, data: models, selectable: false, tableWrapperClassName: styles.modelsTable }) })] }));
}
