import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import TagsInput from '@jetbrains/ring-ui/components/tags-input/tags-input';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import { ListProps } from '@jetbrains/ring-ui/components/popup-menu/popup-menu';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './product-field.css';
function ProductField({ allProducts, availableProducts }) {
    const { setValue, watch } = useFormContext();
    const products = watch('products');
    const productOptions = useMemo(() => buildProductOptions(allProducts, availableProducts), [allProducts, availableProducts]);
    return (_jsx("div", { ...assignTestId('application-filter-product'), children: _jsx(TagsInput, { id: "productsField", label: "IDEs", size: Size.FULL, placeholder: isEmpty(products) ? 'All' : '', dataSource: () => productOptions, tags: productOptions.filter(tag => products === null || products === void 0 ? void 0 : products.includes(tag.key)), filter: true, onAddTag: ({ tag }) => setValue('products', products ? [...products, tag.key] : [tag.key], {
                shouldDirty: true
            }), onRemoveTag: ({ tag }) => products &&
                setValue('products', products.filter(item => item !== tag.key)) }) }));
}
export default ProductField;
function buildProductOptions(allProducts, availableProducts) {
    const availableOptions = toOptions(availableProducts ? allProducts.filter(isAvailable) : allProducts);
    const unavailableOptions = toOptions(availableProducts ? allProducts.filter(it => !isAvailable(it)) : []);
    return unavailableOptions.length
        ? [
            ...availableOptions,
            ...[
                {
                    key: 'tools-separator-before-exclude-section',
                    rgItemType: ListProps.Type.SEPARATOR,
                    description: (_jsx("span", { ...assignTestId('tools-separator-before-exclude-section'), children: "Excluded from the profile" }))
                }
            ],
            ...unavailableOptions.map(it => ({ ...it, className: styles.unavailable }))
        ]
        : availableOptions;
    function toOptions(products) {
        return products.map(product => ({
            key: product.id,
            label: product.name
        }));
    }
    function isAvailable(product) {
        return (availableProducts || []).some(available => available.id === product.id);
    }
}
