// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloadProgressBarWrapper__fbe {
  width: calc(12 * var(--ring-unit));
  display: inline-block;
  text-align: right;
}

.noPaddingButton__d1a {
  padding: 0;
}

.tableRightToolbar__b55 {
  flex-direction: row-reverse;
}

.danger__a64 {
  color: var(--ring-error-color);
}

.channelsText__a5a {
  margin-right: calc(var(--ring-unit) * 0.75);
}

.actionButtons__c6b {
  display: flex;
  gap: var(--ring-unit);
}

.infoIcon__ac7 {
  color: var(--ring-icon-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/plugins/plugin-page/plugin-page.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".downloadProgressBarWrapper {\n  width: calc(12 * var(--ring-unit));\n  display: inline-block;\n  text-align: right;\n}\n\n.noPaddingButton {\n  padding: 0;\n}\n\n.tableRightToolbar {\n  flex-direction: row-reverse;\n}\n\n.danger {\n  color: var(--ring-error-color);\n}\n\n.channelsText {\n  margin-right: calc(var(--ring-unit) * 0.75);\n}\n\n.actionButtons {\n  display: flex;\n  gap: var(--ring-unit);\n}\n\n.infoIcon {\n  color: var(--ring-icon-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadProgressBarWrapper": `downloadProgressBarWrapper__fbe`,
	"noPaddingButton": `noPaddingButton__d1a`,
	"tableRightToolbar": `tableRightToolbar__b55`,
	"danger": `danger__a64`,
	"channelsText": `channelsText__a5a`,
	"actionButtons": `actionButtons__c6b`,
	"infoIcon": `infoIcon__ac7`
};
export default ___CSS_LOADER_EXPORT___;
