import { jsx as _jsx } from "react/jsx-runtime";
import { ConstituentTrialActivationDialogContent } from '@modules/constituents/constituent-trial/constituent-trial-activation-dialog-content';
import { RequestingTrialLoader } from '@modules/constituents/constituent-trial/requesting-trial-loader';
import { ConstituentTrialOfflineModeDialogContent } from '@modules/constituents/constituent-trial/constituent-trial-offline-mode-dialog-content';
import { useRequestConstituentTrial } from '@modules/constituents/constituent-trial/use-request-constituent-trial';
import auth from '@common/auth/auth';
export function AiTrialContent({ onClose, changeStep, ...rest }) {
    const { offlineModeEnabled } = auth.getFeatures();
    const { mutate, isLoading } = useRequestConstituentTrial('ai', changeStep);
    if (isLoading) {
        return _jsx(RequestingTrialLoader, { ...rest });
    }
    if (offlineModeEnabled) {
        return (_jsx(ConstituentTrialOfflineModeDialogContent, { onCancel: onClose, onSkip: changeStep, ...rest }));
    }
    return (_jsx(ConstituentTrialActivationDialogContent, { product: "AI Enterprise", onActivateTrial: mutate, onCancel: onClose, onSkip: changeStep, ...rest }));
}
