import { format, parseISO } from 'date-fns';
/**
 * e.x. for given 12635 it returns 1,6k
 * @param {number} num
 * @return {string}
 */
export function formatNumberShortening(num) {
    const res = Math.abs(num) > 999
        ? `${(Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1)}k`
        : `${Math.sign(num) * Math.abs(num)}`;
    return res.replace('.', ',');
}
export function localDateString(date = new Date()) {
    return date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
}
/**
 * Function that changes date string to given format.
 * Used library: date-fns
 * @param {string} date
 * @param {string} dateFormat
 * @return {string}
 */
export function formatDateString(date, dateFormat) {
    const formatedDate = format(parseISO(date), dateFormat);
    return formatedDate;
}
export function millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    const seconds = (millis % 60000) / 1000;
    return `${(minutes > 0 ? `${minutes} min ` : '') + (seconds < 10 ? '0' : '') + seconds.toFixed(0)} s`;
}
export function parseDuration(durationString) {
    var _a;
    const regex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?/;
    const matches = (_a = durationString.match(regex)) !== null && _a !== void 0 ? _a : [];
    const [, years = 0, months = 0, weeks = 0, days = 0, hours = 0, minutes = 0, seconds = 0] = matches.map(match => (match ? parseInt(match, 10) : 0));
    // Convert weeks to days as date-fns does not support weeks directly
    const totalDays = days + weeks * 7;
    return { years, months, days: totalDays, hours, minutes, seconds };
}
