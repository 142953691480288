import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { ConstituentsList } from '../constituents-list/constituents-list';
import styles from './user-constituents.css';
export function UserConstituents({ constituents }) {
    const enabledConstituentIds = useMemo(() => {
        return constituents.reduce((acc, constituent) => {
            if (constituent.state === 'CONFIGURED') {
                acc.push(constituent.constituentId);
            }
            return acc;
        }, []);
        // fixme: there is should be user in dependency array, add it and remove eslint-disable-next-line  when IDES-5338 will be fixed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx("div", { className: styles.wrapper, children: _jsxs("div", { className: styles.constituents, ...assignTestId('user-constituents'), children: [_jsx("div", { className: styles.constituentsTitle, children: "IDE Services" }), !!enabledConstituentIds.length && (_jsx(ConstituentsList, { className: styles.constituentList, constituents: enabledConstituentIds })), !enabledConstituentIds.length && (_jsx("p", { className: styles.emptyConstituents, ...assignTestId('constituents-disabled-message'), children: "No IDE services enabled" }))] }) }));
}
