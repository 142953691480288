import z from 'zod';
import { apiOpenAiPlatformProviderPresetValues } from '@api/@types';
const tOpenAiPlatformModel = z.object({
    deploymentName: z.string().nullish(), // custom rule defined in superRefine below
    id: z.string(),
    modelName: z.string(),
    tokenLimit: z.number()
});
export const tOpenAiFormSchema = z
    .object({
    apiKey: z.string().min(5, 'API Key is required').nullable(),
    id: z.string().optional(),
    preset: z.enum(apiOpenAiPlatformProviderPresetValues),
    url: z.string().url('Endpoint must be a valid URL'),
    models: z.array(tOpenAiPlatformModel)
})
    .superRefine(({ preset, models }, refinementContext) => {
    if (preset === 'AZURE' && models.find(m => !m.deploymentName || m.deploymentName === '')) {
        return refinementContext.addIssue({
            code: 'custom',
            message: 'Deployment name is required',
            path: ['models', 'deploymentName']
        });
    }
    return refinementContext;
});
