import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react/jsx-runtime';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { TextDiff } from './text-diff';
import styles from './extract-properties-diff.css';
function serializeValue(value) {
    if (typeof value === 'number' || typeof value === 'boolean') {
        return `${value}`;
    }
    if (value && typeof value === 'object') {
        return JSON.stringify(value, null, 3);
    }
    return !value ? '' : `${value}`;
}
function ItemProperty({ name, value, prevValue }) {
    const text = serializeValue(value);
    const oldText = serializeValue(prevValue);
    return (_jsxs("div", { className: styles.property, ...assignTestId('event-details-field', `event-details-field-${name.toLowerCase().replace(/\s/g, '-')}`), children: [_jsxs("span", { ...assignTestId('property-name'), className: styles.propertyName, children: [name, ":"] }), ' ', _jsx("code", { className: styles.propertyValue, children: _jsx(TextDiff, { newText: text, oldText: oldText }) })] }, name));
}
export function PropertiesDiff(props) {
    const data = prettifyConstituentsSnapshotLabels(props.data);
    const prevData = prettifyConstituentsSnapshotLabels(props.prevData || {});
    return Object.keys(data)
        .filter(key => key !== 'id')
        .map(key => {
        const content = data[key];
        if (Array.isArray(content)) {
            const prevContent = (prevData && prevData[key] && prevData[key]) || [];
            if (content.length || prevContent.length) {
                const getId = ({ id }, idx) => (!id ? `${idx}` : id);
                const newContent = [...content];
                const removedContent = prevContent.filter((it, idx) => {
                    const id = getId(it, idx);
                    return !newContent.some((newIt, i) => getId(newIt, i) === id);
                });
                return (_jsxs(Fragment, { children: [newContent.map((it, idx) => {
                            const id = getId(it, idx);
                            return (_jsx(ItemProperty, { name: `${key}[${id}]`, value: it, prevValue: prevContent.find((prevIt, i) => getId(prevIt, i) === id) }, `${key}-${id}`));
                        }), removedContent.map((it, idx) => {
                            return (_jsx(ItemProperty, { name: `${key}[${getId(it, idx)}]`, value: "", prevValue: it }, `${key}-${getId(it, idx)}`));
                        })] }, key));
            }
            return undefined;
        }
        return (_jsx(ItemProperty, { name: key, value: content, prevValue: prevData ? prevData[key] : undefined }, key));
    })
        .filter(it => !!it);
}
function prettifyConstituentsSnapshotLabels(data) {
    const { constituents, ...rest } = data;
    return constituents
        ? {
            ...rest,
            'Code with Me': constituents.cwm,
            'IDE Provisioner': constituents.idep,
            'AI Enterprise': constituents.ai
        }
        : rest;
}
