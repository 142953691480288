// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label__e68 {
  display: block;
  color: var(--ring-secondary-color);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
  margin-bottom: calc(var(--ring-unit) * 0.5);
}

.tagsContainer__fd3 {
  display: flex;
  padding: calc(var(--ring-unit) / 4) calc(var(--ring-unit) / 2);
  border: calc(var(--ring-border-radius) / 4) solid var(--ring-borders-color);
  border-radius: calc(var(--ring-unit) / 2);
  cursor: pointer;
}

.dropdownContainer__ec6 {
  min-width: 100%;
}

.popupContainer__f96 {
  margin-top: calc(var(--ring-unit) / 2);
  padding: var(--ring-unit);
  width: calc(var(--ring-unit) * 50);
  max-height: 350px;
}

.popupContainer__f96 > div {
  margin-left: calc(var(--ring-unit) * -1.5);
  margin-bottom: var(--ring-unit);
}

.placeholder__aba {
  opacity: 0.6;
}

.titleContainer__cef {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/modules/applications/tools-filter/filter-dialog/fields/build-field/build-field.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kCAAkC;EAClC,wCAAwC;EACxC,2CAA2C;EAC3C,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,8DAA8D;EAC9D,2EAA2E;EAC3E,yCAAyC;EACzC,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sCAAsC;EACtC,yBAAyB;EACzB,kCAAkC;EAClC,iBAAiB;AACnB;;AAEA;EACE,0CAA0C;EAC1C,+BAA+B;AACjC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".label {\n  display: block;\n  color: var(--ring-secondary-color);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n  margin-bottom: calc(var(--ring-unit) * 0.5);\n}\n\n.tagsContainer {\n  display: flex;\n  padding: calc(var(--ring-unit) / 4) calc(var(--ring-unit) / 2);\n  border: calc(var(--ring-border-radius) / 4) solid var(--ring-borders-color);\n  border-radius: calc(var(--ring-unit) / 2);\n  cursor: pointer;\n}\n\n.dropdownContainer {\n  min-width: 100%;\n}\n\n.popupContainer {\n  margin-top: calc(var(--ring-unit) / 2);\n  padding: var(--ring-unit);\n  width: calc(var(--ring-unit) * 50);\n  max-height: 350px;\n}\n\n.popupContainer > div {\n  margin-left: calc(var(--ring-unit) * -1.5);\n  margin-bottom: var(--ring-unit);\n}\n\n.placeholder {\n  opacity: 0.6;\n}\n\n.titleContainer {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `label__e68`,
	"tagsContainer": `tagsContainer__fd3`,
	"dropdownContainer": `dropdownContainer__ec6`,
	"popupContainer": `popupContainer__f96`,
	"placeholder": `placeholder__aba`,
	"titleContainer": `titleContainer__cef`
};
export default ___CSS_LOADER_EXPORT___;
