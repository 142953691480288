import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { formatDistance } from 'date-fns';
import Icon from '@jetbrains/ring-ui/components/icon';
import info from '@jetbrains/icons/info';
import styles from '@common/badge/specialized-badges/specialized-badges.css';
import { PopupMessage } from '@common/popup-message/popup-message';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { Badge } from '@common/badge';
import { getTrialEndsInterval } from '../../common/trial/get-trial-ends-interval';
export function TrialOngoingBadge(props) {
    const { tooltipContent, tooltipTitle, endDate } = props;
    const trialEndInterval = getTrialEndsInterval(endDate);
    const badge = (_jsxs(Badge, { variant: Badge.variant.Filled, color: Badge.color.Disabled, size: Badge.size.Md, className: classNames(styles.badge, {
            [styles.primary]: trialEndInterval === 'moreThanOneMonth',
            [styles.warning]: trialEndInterval === 'oneMonth',
            [styles.error]: trialEndInterval === 'twoWeeks'
        }), ...assignTestId('trial-end-date'), children: ["Trial ends in ", formatDistance(endDate, new Date()), ' ', tooltipContent && _jsx(Icon, { className: styles.icon, glyph: info })] }));
    if (!tooltipContent) {
        return badge;
    }
    return (_jsx(PopupMessage, { title: tooltipTitle, anchor: badge, children: tooltipContent }));
}
