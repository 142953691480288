import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Radio from '@jetbrains/ring-ui/components/radio/radio';
import Icon from '@jetbrains/ring-ui/components/icon';
import trashIcon from '@jetbrains/icons/trash';
import classNames from 'classnames';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { ProviderFormBase } from '@modules/ai/ai-settings-form/common/provider-form-base';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './provider-form-base.css';
export function GeneralProviderFormBase({ isDefaultProvider, onDefaultProviderChange, onDelete, className, ...rest }) {
    return (_jsx(ProviderFormBase, { ...rest, className: classNames(className, { [styles.isDefaultProvider]: isDefaultProvider }), ...assignTestId('general-provider-form', rest), actions: _jsxs(_Fragment, { children: [_jsx(Tooltip, { title: "Only one default AI provider can be selected", children: _jsx(Radio.Item, { ...assignTestId('default-provider-radio'), checked: isDefaultProvider, value: "", onChange: onDefaultProviderChange, children: "Default" }) }), _jsx(Icon, { ...assignTestId('remove'), glyph: trashIcon, onClick: onDelete, className: styles.deleteIcon })] }) }));
}
