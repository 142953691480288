export const DEFAULT_PRESET_VALUES = {
    AZURE: {
        preset: 'AZURE',
        url: '',
        apiKey: '',
        models: [
            {
                id: 'openai-chat-gpt',
                modelName: 'gpt-3.5-turbo',
                deploymentName: '',
                tokenLimit: 16385
            },
            {
                id: 'openai-gpt-4',
                modelName: 'gpt-4',
                deploymentName: '',
                tokenLimit: 128000
            },
            {
                id: 'openai-gpt-4o-mini',
                modelName: 'gpt-4o-mini',
                deploymentName: '',
                tokenLimit: 128000
            }
        ]
    },
    OPENAI: {
        preset: 'OPENAI',
        url: 'https://api.openai.com/v1',
        apiKey: '',
        models: [
            {
                id: 'openai-chat-gpt',
                modelName: 'gpt-3.5-turbo',
                deploymentName: '',
                tokenLimit: 16385
            },
            {
                id: 'openai-gpt-4',
                modelName: 'gpt-4',
                deploymentName: '',
                tokenLimit: 128000
            },
            {
                id: 'openai-gpt-4o-mini',
                modelName: 'gpt-4o-mini',
                deploymentName: '',
                tokenLimit: 128000
            }
        ]
    }
};
export const OPTIONAL_MODELS = [
    {
        id: 'openai-gpt-4o',
        modelName: 'gpt-4o',
        deploymentName: '',
        tokenLimit: 128000
    }
];
export const GOOGLE_DEFAULT_VALUES = {
    project: '',
    region: '',
    token: '',
    models: [
        {
            id: 'google-chat-gemini-pro-1.5',
            modelName: 'gemini-1.5-pro',
            tokenLimit: 1000000
        },
        {
            id: 'google-chat-gemini-flash-1.5',
            modelName: 'gemini-1.5-flash',
            tokenLimit: 1000000
        }
    ]
};
export const BEDROCK_DEFAULT_VALUES = {
    accessKey: '',
    secretKey: '',
    region: '',
    models: [
        {
            id: 'anthropic-claude-3.5-sonnet',
            modelName: 'anthropic.claude-3-5-sonnet-20241022-v2:0'
        },
        {
            id: 'anthropic-claude-3.5-haiku',
            modelName: 'anthropic.claude-3-5-haiku-20241022-v1:0'
        }
    ]
};
export const AI_PROVIDERS = {
    openai: {
        key: 'openai',
        label: 'OpenAI'
    },
    jetbrains: {
        key: 'jetbrains',
        label: 'JetBrains AI'
    },
    google: {
        key: 'google',
        label: 'Google Vertex AI'
    },
    bedrock: {
        key: 'bedrock',
        label: 'Amazon Bedrock'
    },
    huggingFace: {
        key: 'huggingFace',
        label: 'Hugging Face'
    },
    mellum: {
        key: 'mellum',
        label: 'JetBrains Mellum'
    }
};
export const DEFAULT_PROVIDER_VALUES = {
    openai: {
        id: 'openai',
        ...DEFAULT_PRESET_VALUES.AZURE
    },
    jetbrains: {
        id: 'jetbrains'
    },
    google: {
        id: 'google',
        ...GOOGLE_DEFAULT_VALUES
    },
    bedrock: {
        id: 'bedrock',
        ...BEDROCK_DEFAULT_VALUES
    },
    huggingFace: {
        id: 'huggingFace'
    },
    mellum: {
        id: 'mellum'
    }
};
export const AI_PRESET_OPTIONS = [
    {
        key: 'AZURE',
        label: 'Azure OpenAI'
    },
    {
        key: 'OPENAI',
        label: 'OpenAI Platform'
    }
];
export const FORM_INITIAL_DATA = {
    defaultProvider: 'jetbrains',
    activeIdeProfilesCount: 0,
    activeUsersCount: 0,
    availableProviders: [],
    allowDataCollection: false,
    disabledByLimitsUsersCount: 0,
    assignedUsersLimit: undefined,
    provider: {
        jetbrains: {
            id: 'jetbrains'
        }
    }
};
export const HUGGING_FACE_MODEL_CONFIGURATIONS = [
    { id: 'llama-3.1-70B', label: 'llama-3.1' }
];
