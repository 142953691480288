import { useMutation, useQuery } from '@tanstack/react-query';
import { formatApiError } from '@api/errors';
import { getConstituentsList, requestConstituentTrial } from '@api/constituents-common';
import { getMutationAlertMessages, getQueryAlertMessages } from '@app/common/queries/formatters/alerts';
import queryClient, { DEFAULT_POLLING_INTERVAL } from '@app/common/queries/query-client';
export const keys = {
    constituents: ['constituents']
};
const constituentsListQueryArgs = {
    queryKey: keys.constituents,
    queryFn: () => getConstituentsList(),
    meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load list of products'))
};
export function useConstituentState(constituentId) {
    return useQuery({
        ...constituentsListQueryArgs,
        select: constituents => {
            const constituent = constituents.find(it => it.id === constituentId);
            return constituent === null || constituent === void 0 ? void 0 : constituent.state;
        }
    });
}
export function useGetConstituents() {
    return useQuery({
        ...constituentsListQueryArgs
    });
}
export function useConstitutionOverusePolling(enabled = true) {
    return useQuery({
        ...constituentsListQueryArgs,
        refetchInterval: DEFAULT_POLLING_INTERVAL,
        enabled
    });
}
export function useRequestConstituentTrialMutation(constituentId) {
    return useMutation({
        mutationFn: () => requestConstituentTrial(constituentId),
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to request trial'), () => 'Trial requested successfully')
    });
}
