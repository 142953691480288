// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mellumBanner__c8b {
  max-width: 700px;
}

.modelsTable__deb {
  padding-bottom: calc(var(--ring-unit) * 2);
  position: relative;

  table thead {
    position: relative;

    &:after {
      top: 100%;
    }
  }

  /* Hack to make a proper spacing */
  table tbody {
    &:before {
      content: '';
      display: block;
      height: calc(var(--ring-unit) * 1.75);
    }

    &:after {
      content: '';
      display: block;
      height: calc(var(--ring-unit) * 1.75);
    }

    td {
      padding-top: calc(var(--ring-unit) * 0.75);
      padding-bottom: calc(var(--ring-unit) * 0.75);
    }
  }

  & table:after {
    content: '';
    position: absolute;
    border-bottom: 1px solid var(--ring-line-color);
    width: calc(100% - calc(var(--ring-unit) * 4));
    transform: translateX(calc(var(--ring-unit) * 4));
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/common/forms/mellum-ai-provider-form/mellum-ai-provider-form.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,0CAA0C;EAC1C,kBAAkB;;EAElB;IACE,kBAAkB;;IAElB;MACE,SAAS;IACX;EACF;;EAEA,kCAAkC;EAClC;IACE;MACE,WAAW;MACX,cAAc;MACd,qCAAqC;IACvC;;IAEA;MACE,WAAW;MACX,cAAc;MACd,qCAAqC;IACvC;;IAEA;MACE,0CAA0C;MAC1C,6CAA6C;IAC/C;EACF;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,+CAA+C;IAC/C,8CAA8C;IAC9C,iDAAiD;EACnD;AACF","sourcesContent":[".mellumBanner {\n  max-width: 700px;\n}\n\n.modelsTable {\n  padding-bottom: calc(var(--ring-unit) * 2);\n  position: relative;\n\n  table thead {\n    position: relative;\n\n    &:after {\n      top: 100%;\n    }\n  }\n\n  /* Hack to make a proper spacing */\n  table tbody {\n    &:before {\n      content: '';\n      display: block;\n      height: calc(var(--ring-unit) * 1.75);\n    }\n\n    &:after {\n      content: '';\n      display: block;\n      height: calc(var(--ring-unit) * 1.75);\n    }\n\n    td {\n      padding-top: calc(var(--ring-unit) * 0.75);\n      padding-bottom: calc(var(--ring-unit) * 0.75);\n    }\n  }\n\n  & table:after {\n    content: '';\n    position: absolute;\n    border-bottom: 1px solid var(--ring-line-color);\n    width: calc(100% - calc(var(--ring-unit) * 4));\n    transform: translateX(calc(var(--ring-unit) * 4));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mellumBanner": `mellumBanner__c8b`,
	"modelsTable": `modelsTable__deb`
};
export default ___CSS_LOADER_EXPORT___;
