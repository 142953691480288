import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import addIcon from '@jetbrains/icons/add';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import chevronDown from '@jetbrains/icons/chevron-down';
import Select from '@jetbrains/ring-ui/components/select/select';
import { isNil } from 'lodash';
import { Directions } from '@jetbrains/ring-ui/components/popup/popup.consts';
import { getSettingsTemplates } from '@modules/ide-provisioner/ide-settings/settings-templates';
import { KEY_VALUE_LIST_KIND } from '@modules/ide-provisioner/ide-settings/settings-builders';
// eslint-disable-next-line no-restricted-imports
import utilStyles from '@common/util/utils.css';
import { assignTestId } from '@common/infra/tests/assign-test-id';
const PopupDirections = [Directions.BOTTOM_CENTER, Directions.BOTTOM_RIGHT, Directions.BOTTOM_LEFT];
function getSelectOption(setting) {
    var _a, _b;
    if (setting.kind === KEY_VALUE_LIST_KIND) {
        const { description, ...rest } = setting;
        return {
            key: (_a = setting.description) !== null && _a !== void 0 ? _a : '',
            label: setting.description,
            originalSetting: rest
        };
    }
    return {
        key: (_b = setting.description) !== null && _b !== void 0 ? _b : '',
        label: setting.description,
        originalSetting: setting
    };
}
export default function TemplateSelector({ onChange }) {
    const templates = getSettingsTemplates();
    const options = templates.map(getSelectOption);
    const onSelect = (selectItem) => {
        if (isNil(selectItem)) {
            return;
        }
        if (selectItem.originalSetting) {
            onChange(selectItem.originalSetting);
        }
    };
    const inlineAnchor = useCallback(({ wrapperProps, buttonProps, popup }) => (_jsxs("span", { ...wrapperProps, children: [_jsxs(Button, { className: utilStyles.zeroPadded, icon: addIcon, primary: true, ...buttonProps, ...assignTestId('add-ide-setting-button'), children: ["Add IDE setting ", _jsx(Icon, { glyph: chevronDown })] }), popup] })), []);
    return (_jsx(Select, { type: Select.Type.CUSTOM, customAnchor: inlineAnchor, filter: { fuzzy: true }, data: options, onSelect: onSelect, directions: PopupDirections }));
}
