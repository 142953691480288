// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentLayout__a23 {
  display: flex;
  flex-direction: column;
  max-width: 750px;
}

.contentSection__c5c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--ring-unit) * 0.5);
  margin-bottom: calc(var(--ring-unit) * 3);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/offline-mode/offline-mode.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iCAAiC;EACjC,yCAAyC;AAC3C","sourcesContent":[".contentLayout {\n  display: flex;\n  flex-direction: column;\n  max-width: 750px;\n}\n\n.contentSection {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: calc(var(--ring-unit) * 0.5);\n  margin-bottom: calc(var(--ring-unit) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentLayout": `contentLayout__a23`,
	"contentSection": `contentSection__c5c`
};
export default ___CSS_LOADER_EXPORT___;
