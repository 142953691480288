import { createEnumParam, NumberParam, StringParam } from 'serialize-query-params';
import { pagingSortOrder } from '@api/paging-utils';
import { useSyncedSearchArgs } from '@common/navigation/use-synced-search-args';
import { sortableColumns } from './constants';
export function usePluginParams() {
    return useSyncedSearchArgs(tsTypeToQuerySchema, validatePluginFilter);
}
function validatePluginFilter(parsed) {
    var _a;
    return {
        sortKey: parsed.sortKey || 'version',
        sortOrder: parsed.sortOrder || 'DESC',
        searchValue: (_a = parsed.searchValue) !== null && _a !== void 0 ? _a : '',
        page: parsed.page && parsed.page > 0 ? parsed.page : 1
    };
}
const tsTypeToQuerySchema = {
    sortKey: createEnumParam(Object.values(sortableColumns)),
    sortOrder: createEnumParam(pagingSortOrder),
    searchValue: StringParam,
    page: NumberParam
};
