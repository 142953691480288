import { uniq } from 'lodash';
export function getUpdatedSelection(key, items, dataListOptions, comparisonKey) {
    if (!items) {
        return [key];
    }
    const wasPreviouslyChecked = items.some(it => it === key);
    const parents = items.filter(it => isChildOf(key, it));
    if (!parents.length) {
        if (wasPreviouslyChecked) {
            return items.filter(it => it !== key);
        }
        return items.filter(it => !isChildOf(it, key)).concat([key]);
    }
    return items
        .filter(it => !parents.includes(it))
        .concat(uniq(parents.flatMap(getChildren)))
        .filter(it => it !== key && !isChildOf(key, it));
    function getChildren(parentKey) {
        const parentOption = dataListOptions.find(it => it[comparisonKey] === parentKey);
        return ((parentOption === null || parentOption === void 0 ? void 0 : parentOption.items) || []).map(it => it[comparisonKey]);
    }
    function isChildOf(childKey, parentKey) {
        const potentialParentWithoutAsterisk = parentKey.split('*')[0];
        return (parentKey !== childKey &&
            (childKey.startsWith(parentKey) || childKey.startsWith(potentialParentWithoutAsterisk)));
    }
}
