// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status__cdb {
  margin-bottom: var(--ring-unit);
}

.statusError__be7 {
  color: var(--ring-error-color);
}

.statusSuccess__fc1 {
  color: var(--ring-success-color);
}

.messages__eac {
  margin-top: var(--ring-unit);
}

.message__aa1 {
  padding: var(--ring-unit) 0;
  display: block;
}

.messageValue__c6b {
  word-break: break-all;
  padding: var(--ring-unit);
  background-color: var(--ring-sidebar-background-color);
  border-radius: var(--ring-border-radius);
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-settings-form/common/provider-test-connection-result.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,2BAA2B;EAC3B,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,sDAAsD;EACtD,wCAAwC;EACxC,cAAc;AAChB","sourcesContent":[".status {\n  margin-bottom: var(--ring-unit);\n}\n\n.statusError {\n  color: var(--ring-error-color);\n}\n\n.statusSuccess {\n  color: var(--ring-success-color);\n}\n\n.messages {\n  margin-top: var(--ring-unit);\n}\n\n.message {\n  padding: var(--ring-unit) 0;\n  display: block;\n}\n\n.messageValue {\n  word-break: break-all;\n  padding: var(--ring-unit);\n  background-color: var(--ring-sidebar-background-color);\n  border-radius: var(--ring-border-radius);\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `status__cdb`,
	"statusError": `statusError__be7`,
	"statusSuccess": `statusSuccess__fc1`,
	"messages": `messages__eac`,
	"message": `message__aa1`,
	"messageValue": `messageValue__c6b`
};
export default ___CSS_LOADER_EXPORT___;
