import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@jetbrains/ring-ui/components/radio/radio';
import Text from '@jetbrains/ring-ui/components/text/text';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import { isNil } from 'lodash';
import { AI_PROVIDERS } from '@modules/ai/common/constants';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import NavigationLink from '@common/navigation/navigation-link';
import { getProfileConstituents } from '@common/store/profiles/selectors';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './provider-selector.css';
export function ProviderSelector({ defaultProvider, availableProviders }) {
    var _a;
    const dispatch = useDispatch();
    const { ai: { provider: selectedProvider } } = useSelector(getProfileConstituents);
    const handleProviderChange = useCallback((provider) => dispatch(ProfileActions.updateAiConstituent({ provider })), [dispatch]);
    return (_jsxs("div", { className: styles.container, children: [_jsx(H3, { children: "AI Provider" }), _jsx("div", { className: styles.radioContainer, ...assignTestId('provider-selector-radio'), children: _jsxs(Radio, { children: [_jsxs(Radio.Item, { checked: isNil(selectedProvider), value: "Default provider", onChange: () => handleProviderChange(undefined), ...assignTestId('provider-selector-radio-item', 'default'), children: ["Always use the default provider ", _jsx(Text, { info: true, children: (_a = AI_PROVIDERS[defaultProvider]) === null || _a === void 0 ? void 0 : _a.label })] }), availableProviders === null || availableProviders === void 0 ? void 0 : availableProviders.map(provider => {
                            var _a;
                            return (_jsx(Radio.Item, { onChange: v => handleProviderChange(v.currentTarget.value), value: provider, checked: selectedProvider === provider, ...assignTestId('provider-selector-radio-item', provider), children: (_a = AI_PROVIDERS[provider]) === null || _a === void 0 ? void 0 : _a.label }));
                        })] }) }), _jsxs(Text, { className: styles.textContainer, info: true, children: ["Manage AI providers in", ' ', _jsx(NavigationLink, { href: "/configuration/license-activation/ai-settings", children: "AI Enterprise settings" })] })] }));
}
