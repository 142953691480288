import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { ConstituentDisabledWidget } from '@modules/constituents/widget/constituent-disabled-widget';
import { getConstituentDialogFirstStep } from '@modules/constituents/constituent-dialog/constituent-dialog-steps';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useModalsController } from '@common/modals/hooks';
import { IdePEnableDialog } from '../../../common/dialogs/idep-settings-dialog/idep-enable-dialog';
export function IdepDisableNoTrialWidget(props) {
    const { licenseType, constituentLicense } = props;
    const modalsController = useModalsController();
    const handleOpenModal = useCallback(async () => {
        const openEnableModal = modalsController.openModal(IdePEnableDialog, {
            startFromStep: getConstituentDialogFirstStep(constituentLicense, licenseType)
        });
        openEnableModal.promise.then(() => {
            openEnableModal.closeModal();
        });
    }, [constituentLicense, licenseType, modalsController]);
    return (_jsx(ConstituentDisabledWidget, { ...props, ...assignTestId('widget-ide-provisioner'), onEnableClick: handleOpenModal, children: _jsx(Text, { children: "Centralize IDE management, optimize plugin selection, and standardize settings, ensuring efficient and unified developer environment" }) }));
}
