import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Content } from '@jetbrains/ring-ui/components/island/island';
import classNames from 'classnames';
import Text from '@jetbrains/ring-ui/components/text/text';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import styles from './requesting-trial-loader.css';
export function RequestingTrialLoader({ contentClassName }) {
    return (_jsxs(_Fragment, { children: [_jsxs(Content, { className: classNames(styles.container, contentClassName), children: [_jsxs(H3, { children: ["Requesting a trial ", _jsx(InlineLoader, {})] }), _jsx(Text, { children: "Your trial will be available soon." })] }), _jsxs(Panel, { children: [_jsx(Button, { disabled: true, "data-test": "activate-trial-button", children: "Start trial" }), _jsx(Button, { disabled: true, "data-test": "skip-trial-button", children: "Skip trial" }), _jsx(Button, { disabled: true, children: "Cancel" })] })] }));
}
