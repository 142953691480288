import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useAiConstituent } from '@app/common/queries/ai-enterprise';
import { useModalsController, useOpenModal } from '@app/common/modals/hooks';
import { getConstituentDialogFirstStep } from '@modules/constituents/constituent-dialog/constituent-dialog-steps';
import { AiDisableDialog } from '../../common/dialogs/ai-disable-dialog/ai-disable-dialog';
import { AiEnableDialog } from '../../common/dialogs/ai-settings-dialog/ai-enable-dialog';
import { AiEnabled } from './ai-enabled/ai-enabled';
import { AiDisabled } from './ai-disabled/ai-disabled';
import { AiDisabledJba } from './ai-disabled-jba/ai-disabled-jba';
export function AiEnterpriseConstituent(props) {
    const { licenseType, constituentLicense, className } = props;
    const modalsController = useModalsController();
    const { data } = useAiConstituent();
    const handleOpenModal = useCallback(async () => {
        const openEnableModal = modalsController.openModal(AiEnableDialog, {
            startFromStep: getConstituentDialogFirstStep(constituentLicense, licenseType)
        });
        openEnableModal.promise.then(() => {
            openEnableModal.closeModal();
        });
    }, [constituentLicense, licenseType, modalsController]);
    const openDisableAiModal = useOpenModal(AiDisableDialog, {});
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled_jba') {
        return _jsx(AiDisabledJba, { className: className });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled') {
        return (_jsx(AiDisabled, { onSetupClick: handleOpenModal, className: className, trialData: { licenseType, license: constituentLicense } }));
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'enabled') {
        return (_jsx(AiEnabled, { aiPlatformData: data.data, onDisableClick: openDisableAiModal, className: className, license: constituentLicense, licenseType: licenseType }));
    }
    return null;
}
