// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listItem__ccb {
  display: flex;
  gap: calc(var(--ring-unit) * 2);
  justify-content: space-between;
}

.container__ed4 > div > div {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 2);
}

.title__af3 {
  margin-bottom: var(--ring-unit);
}

.stepDescription__e3b {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) / 2);
  flex: 1;
}

.copiedText__bfb {
  border: 1px solid var(--ring-borders-color);
  padding: calc(var(--ring-unit) / 2) calc(var(--ring-unit) * 2);
  border-radius: var(--ring-border-radius);
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/modules/constituents/constituent-trial/constituent-trial-offline-mode-dialog-content.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,OAAO;AACT;;AAEA;EACE,2CAA2C;EAC3C,8DAA8D;EAC9D,wCAAwC;EACxC,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":[".listItem {\n  display: flex;\n  gap: calc(var(--ring-unit) * 2);\n  justify-content: space-between;\n}\n\n.container > div > div {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 2);\n}\n\n.title {\n  margin-bottom: var(--ring-unit);\n}\n\n.stepDescription {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) / 2);\n  flex: 1;\n}\n\n.copiedText {\n  border: 1px solid var(--ring-borders-color);\n  padding: calc(var(--ring-unit) / 2) calc(var(--ring-unit) * 2);\n  border-radius: var(--ring-border-radius);\n  width: auto;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": `listItem__ccb`,
	"container": `container__ed4`,
	"title": `title__af3`,
	"stepDescription": `stepDescription__e3b`,
	"copiedText": `copiedText__bfb`
};
export default ___CSS_LOADER_EXPORT___;
