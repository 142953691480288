import auth from '@common/auth/auth';
export var ConstituentDialogSteps;
(function (ConstituentDialogSteps) {
    ConstituentDialogSteps[ConstituentDialogSteps["TRIAL"] = 0] = "TRIAL";
    ConstituentDialogSteps[ConstituentDialogSteps["FORM"] = 1] = "FORM";
})(ConstituentDialogSteps || (ConstituentDialogSteps = {}));
export function getConstituentDialogFirstStep(constituentLicense, plan) {
    const { allowSelfServiceTrial } = auth.getFeatures();
    return constituentLicense.trial.state === 'AVAILABLE' &&
        plan === 'POSTPAID' &&
        allowSelfServiceTrial
        ? ConstituentDialogSteps.TRIAL
        : ConstituentDialogSteps.FORM;
}
