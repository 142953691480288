import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { AI_PROVIDERS } from '@modules/ai/common/constants';
import { ProviderTestConnectionResult } from '@modules/ai/ai-settings-form/common/provider-test-connection-result';
import { assignTestId } from '@common/infra/tests/assign-test-id';
export function ProviderTestConnectionResultDialog({ provider, testResult, onCloseAttempt, ...rest }) {
    var _a;
    return (_jsxs(Dialog, { onCloseAttempt: onCloseAttempt, show: true, trapFocus: true, ...assignTestId('provider-test-connection-result-dialog', rest), children: [_jsxs(Header, { children: [((_a = AI_PROVIDERS[provider.id]) === null || _a === void 0 ? void 0 : _a.label) || '', " Test Connection"] }), _jsx(Content, { children: _jsx(ProviderTestConnectionResult, { testResult: testResult }) }), _jsx(Panel, { children: _jsx(Button, { "data-test": "confirm-reject-button", onClick: onCloseAttempt, children: "Ok" }) })] }));
}
