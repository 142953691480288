import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from '@jetbrains/ring-ui/components/select/select';
import Icon from '@jetbrains/ring-ui/components/icon';
import infoIcon from '@jetbrains/icons/info';
import Text from '@jetbrains/ring-ui/components/text/text';
import { Directions } from '@jetbrains/ring-ui/components/popup/popup.consts';
import classNames from 'classnames';
import { apiFilterIdeModeValues } from '@api/@types';
import { getIdeModeName } from '@modules/remote-development/get-ide-mode-name';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { Required } from '@common/form/important-asterisk';
import { PopupMessage } from '@common/popup-message/popup-message';
import styles from './selector.css';
export function RemoteDevelopmentSelector({ onRemoteEnvironmentSelect }) {
    const remoteDevItems = apiFilterIdeModeValues.map(env => createRemoteEnvironmentSelectItem(env));
    const handleSelectRemoteEnvironment = (selected) => {
        if (selected) {
            onRemoteEnvironmentSelect(selected.remoteEnvironment);
        }
    };
    return (_jsxs("div", { ...assignTestId('remote-environment-wrapper'), children: [_jsxs("p", { ...assignTestId('remote-environment-label'), className: classNames(styles.label, styles.titleWithIcon), children: [Required('Dev Environment'), _jsx(PopupMessage, { anchor: _jsx(Icon, { glyph: infoIcon }), directions: [Directions.RIGHT_BOTTOM], children: _jsx(Text, { children: "Local or remote development environment. At this time, only settings available for the local environment can be previewed on this page." }) })] }), _jsx(Select, { ...assignTestId('remote-environment-select'), className: styles.selector, onSelect: handleSelectRemoteEnvironment, data: remoteDevItems, 
                // Hardcoded for IDES-6569, after completing on BE please remove below 3 line and uncomment rest
                selected: remoteDevItems.find(item => item.remoteEnvironment === 'local'), label: "Local", disabled: true })] }));
}
function createRemoteEnvironmentSelectItem(environment) {
    return {
        remoteEnvironment: environment,
        key: environment,
        label: getIdeModeName(environment)
    };
}
