import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Button from '@jetbrains/ring-ui/components/button/button';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { Dialog } from '@common/dialog/dialog';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './configure-model-dialog.css';
export function ConfigureModelDialog(props) {
    const { children, modelTypeName, onSave, formState, onCancel } = props;
    const { isValid, isDirty } = formState;
    return (_jsxs(Dialog, { show: true, className: styles.wrapper, size: "medium", ...assignTestId('configure-model-dialog'), children: [_jsxs(Header, { children: ["Configure ", modelTypeName, " model"] }), _jsx(Content, { className: styles.contentWrapper, children: _jsx("div", { className: styles.contentInner, children: children }) }), _jsxs(Panel, { className: styles.actions, children: [_jsx(Button, { ...assignTestId('save-btn'), primary: true, disabled: !isValid || !isDirty, onClick: onSave, children: "Save" }), _jsx(Button, { ...assignTestId('cancel-btn'), onClick: onCancel, children: "Cancel" })] })] }));
}
