// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unavailable__d0e {
  color: var(--ring-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/modules/applications/tools-filter/filter-dialog/fields/product-field.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC","sourcesContent":[".unavailable {\n  color: var(--ring-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unavailable": `unavailable__d0e`
};
export default ___CSS_LOADER_EXPORT___;
