// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title__ab1 {
  display: flex;
  align-items: center;

  gap: calc(var(--ring-unit) * 1.5);
}

.info__b75 {
  display: inline;
  color: var(--ring-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/user-details-page/user-details-page.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;;EAEnB,iCAAiC;AACnC;;AAEA;EACE,eAAe;EACf,kCAAkC;AACpC","sourcesContent":[".title {\n  display: flex;\n  align-items: center;\n\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.info {\n  display: inline;\n  color: var(--ring-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `title__ab1`,
	"info": `info__b75`
};
export default ___CSS_LOADER_EXPORT___;
