import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Icon from '@jetbrains/ring-ui/components/icon';
import info from '@jetbrains/icons/info';
import { PopupMessage } from '@common/popup-message/popup-message';
import { Badge } from '@common/badge/general-badge/badge';
import styles from './specialized-badges.css';
export function WarningBadge(props) {
    const { tooltipContent, tooltipTitle, children } = props;
    const badge = (_jsxs(Badge, { variant: Badge.variant.Filled, color: Badge.color.Warning, size: Badge.size.Md, className: classNames(styles.badge, styles.warning), children: [children, " ", tooltipContent && _jsx(Icon, { className: styles.icon, glyph: info })] }));
    if (!tooltipContent) {
        return badge;
    }
    return (_jsx(PopupMessage, { title: tooltipTitle, anchor: badge, children: tooltipContent }));
}
