import { jsx as _jsx } from "react/jsx-runtime";
import RingDialog from '@jetbrains/ring-ui/components/dialog/dialog';
import classNames from 'classnames';
import styles from './dialog.css';
export function Dialog(props) {
    const { size, ...dialogProps } = props;
    return (_jsx(RingDialog, { ...dialogProps, contentClassName: classNames(styles.dialogContent, {
            [styles.wide]: size === 'wide',
            [styles.medium]: size === 'medium'
        }) }));
}
