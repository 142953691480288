import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import classNames from 'classnames';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import { formatDuration } from 'date-fns';
import { ExternalLinks } from '@common/constants/constants';
import NavigationLink from '@common/navigation/navigation-link';
import CopiedText from '@common/clipboard/copied-text/copied-text';
import { parseDuration } from '@common/dates/format';
import styles from './constituent-trial-offline-mode-dialog-content.css';
export function ConstituentTrialOfflineModeDialogContent({ onSkip, onCancel, constituentData, contentClassName }) {
    return (_jsxs(_Fragment, { children: [_jsxs(Content, { className: classNames(styles.container, contentClassName), children: [_jsxs(Text, { children: ["You can try our products for free", ' ', (constituentData === null || constituentData === void 0 ? void 0 : constituentData.duration)
                                ? `for ${formatDuration(parseDuration(constituentData === null || constituentData === void 0 ? void 0 : constituentData.duration))}`
                                : '', ". During the trial, you can add additional paid licenses if you want. When the trial ends, any trial licenses will convert to paid licenses automatically."] }), _jsx(H3, { className: styles.title, children: "To request trial offline please do the following steps" }), _jsxs("div", { className: styles.listItem, children: [_jsx(Text, { children: "1" }), _jsxs("div", { className: styles.stepDescription, children: [_jsx(Text, { children: "Go to your JetBrains Account using the link below" }), _jsx(NavigationLink, { href: ExternalLinks.JB_ACCOUNT, target: "_blank", children: "To JetBrains Account" }), ' ', _jsx(CopiedText, { text: ExternalLinks.JB_ACCOUNT, className: styles.copiedText })] })] }), _jsxs("div", { className: styles.listItem, children: [_jsx(Text, { children: "2" }), _jsx("div", { className: styles.stepDescription, children: _jsx(Text, { children: "Get an activation code in your JetBrains Account using the link above the trial." }) })] }), _jsxs("div", { className: styles.listItem, children: [_jsx(Text, { children: "3" }), _jsxs("div", { className: styles.stepDescription, children: [_jsx(Text, { children: "Activate the trial." }), _jsx(NavigationLink, { href: "/configuration/license-activation", target: "_blank", children: "Go to license and activation" })] })] })] }), _jsxs(Panel, { children: [_jsx(Button, { onClick: onSkip, primary: true, "data-test": "skip-trial-button", children: "Enable without trial" }), _jsx(Button, { onClick: onCancel, children: "Cancel" })] })] }));
}
