import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import classNames from 'classnames';
import { HuggingFaceModelConfigurationDialog } from '@modules/ai/common/forms/hugging-face-ai-provider-form/hugging-face-model-configuration-dialog/hugging-face-model-configuration-dialog';
import { ProviderModels } from '@modules/ai/common/forms/common/provider-models/provider-models';
import { HUGGING_FACE_MODEL_CONFIGURATIONS } from '@modules/ai/common/constants';
const columns = [
    { title: 'ID', id: 'id' },
    { title: 'Model URL', id: 'url', maxWidth: 308 }
];
export function HuggingFaceAiProviderForm(props) {
    var _a, _b, _c, _d, _e;
    const { form, className } = props;
    const { setValue, watch, formState } = form;
    const { errors, dirtyFields } = formState;
    const models = watch('provider.huggingFace.models');
    const setModels = useCallback((models) => setValue('provider.huggingFace.models', models, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true
    }), [setValue]);
    return (_jsx("section", { className: classNames(className), children: _jsx(ProviderModels, { models: models, onModelsChange: setModels, modelDialogComponent: HuggingFaceModelConfigurationDialog, columns: columns, errorMsg: ((_b = (_a = dirtyFields.provider) === null || _a === void 0 ? void 0 : _a.huggingFace) === null || _b === void 0 ? void 0 : _b.models) && ((_e = (_d = (_c = errors.provider) === null || _c === void 0 ? void 0 : _c.huggingFace) === null || _d === void 0 ? void 0 : _d.models) === null || _e === void 0 ? void 0 : _e.message), modelConfigurations: HUGGING_FACE_MODEL_CONFIGURATIONS, headless: true }) }));
}
