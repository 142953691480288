import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Badge } from '@common/badge';
import styles from './automation-token-cell.css';
export function AutomationTokenClientId(props) {
    const { token } = props;
    const isExpired = token.state === 'EXPIRED';
    const isDeleted = token.state === 'DELETED';
    return (_jsxs("span", { className: classNames({ [styles.expiredTokenClientId]: isExpired || isDeleted }), children: [token.clientId, ' ', isExpired && (_jsx(Badge, { variant: Badge.variant.Filled, color: Badge.color.Warning, size: Badge.size.Md, className: classNames(styles.badge, styles.expiredBadge), children: "Expired" })), isDeleted && (_jsx(Badge, { variant: Badge.variant.Filled, color: Badge.color.Disabled, size: Badge.size.Md, className: classNames(styles.badge, styles.deletedBadge), children: "Deleted" }))] }));
}
