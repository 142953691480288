import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import Text from '@jetbrains/ring-ui/components/text/text';
import { addDays, endOfToday, isSameDay, parseISO, startOfDay } from 'date-fns';
import { makePluginDownloadPath } from '@modules/plugins/plugins-paths';
import { formatNumberShortening } from '@common/dates/format';
import NavigationLink from '@common/navigation/navigation-link';
import { usePluginDownloads } from '@common/queries/plugins';
import { StatisticsWidgetBarChart } from '@common/widgets/statistics-widget/statistics-widget-bar-chart';
import styles from './plugin-downloads-info.css';
export default function PluginDownloads({ pluginId }) {
    const { data, isFetching } = usePluginDownloads(pluginId, 'LAST_7_DAYS');
    const encodedPluginId = encodeURIComponent(pluginId);
    const intervals = useMemo(() => {
        const numberOfDays = 7;
        const startOfInterval = startOfDay(addDays(endOfToday(), 1 - numberOfDays));
        const formattedIntervals = ((data === null || data === void 0 ? void 0 : data.entries) || []).map(it => ({
            from: parseISO(it.date),
            total: it.downloads
        }));
        return [...new Array(numberOfDays)].map((_, idx) => {
            const date = addDays(startOfInterval, idx);
            return ((formattedIntervals || []).filter(it => isSameDay(it.from, date))[0] || {
                from: date,
                total: 0
            });
        });
    }, [data]);
    const total = useMemo(() => (intervals || []).reduce((sum, it) => sum + it.total, 0), [intervals]);
    const [activeInterval, setActiveInterval] = useState();
    useEffect(() => {
        setActiveInterval(intervals[intervals.length - 1]);
    }, [intervals]);
    return (_jsxs("div", { children: [isFetching && _jsx(InlineLoader, {}), !isFetching && (_jsxs("div", { className: styles.downloadsContainer, children: [total === 0 && (_jsxs(_Fragment, { children: [_jsxs(Text, { children: [formatNumberShortening(total), " downloads for the last 7 days"] }), _jsx(NavigationLink, { href: makePluginDownloadPath(encodedPluginId), className: styles.statsLink, children: _jsx(Text, { info: true, children: "Show more statistic" }) })] })), total > 0 && activeInterval && (_jsxs("div", { className: styles.downloadsChartContainer, children: [_jsx(StatisticsWidgetBarChart, { className: styles.downloadsWeekChart, height: 30, intervals: intervals, activeInterval: activeInterval, onActivateInterval: setActiveInterval, labelMode: "date-and-number" }), _jsxs("div", { children: ["Downloads for the last 7 days: ", formatNumberShortening(total)] }), _jsx(NavigationLink, { href: makePluginDownloadPath(encodedPluginId), children: _jsx(Text, { info: true, children: "Show more statistic" }) })] }))] }))] }));
}
