import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ConstituentLoadingWidget } from '@modules/constituents/widget/constituent-loading-widget';
import { useCodeWithMeConstituent } from '@common/queries/code-with-me';
import { CodeWithMeLogo } from '../../common/code-with-me-logo/code-with-me-logo';
import { CwmDisabledTrialActiveWidget } from './states/cwm-disabled-trial-active-widget';
import { CwmNotConfiguredWidget } from './states/cwm-not-configured-widget';
import { CwmDisabledNoTrialWidget } from './states/cwm-disabled-no-trial-widget';
import { CwmActiveWidget } from './states/cwm-active-widget';
export function CodeWithMeWidget(props) {
    const { constituentLicense, licenseType } = props;
    const { data, isLoading } = useCodeWithMeConstituent();
    const widgetBaseProps = useMemo(() => ({
        title: 'Code With Me',
        titleHint: 'Code With Me',
        constituentName: 'Code With Me',
        icon: _jsx(CodeWithMeLogo, {}),
        perProfileConfiguration: true
    }), []);
    if (isLoading) {
        return _jsx(ConstituentLoadingWidget, { ...widgetBaseProps });
    }
    switch (data === null || data === void 0 ? void 0 : data.status) {
        case 'enabled':
            if (data.data.activeUsers > 0) {
                return (_jsx(CwmActiveWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
            }
            return (_jsx(CwmNotConfiguredWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
        case 'disabled':
            if (constituentLicense.trial.state === 'ACTIVE') {
                return (_jsx(CwmDisabledTrialActiveWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
            }
            return (_jsx(CwmDisabledNoTrialWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
        default:
            // eslint-disable-next-line no-console
            console.warn(`Unexpected state: ${data === null || data === void 0 ? void 0 : data.status}`);
            return null;
    }
}
