// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentWrapper__b23 {
  width: calc(var(--ring-unit) * 65);
  min-height: calc(var(--ring-unit) * 34);
}

.headerNote__f42 {
  padding: 0 calc(var(--ring-unit) * 4);
}

.globalDisableWarning__cf8 {
  margin-top: calc(var(--ring-unit) / 2);
  color: var(--ring-warning-color);
}

.confirmGlobalAccessWrapper__b76 {
  margin-top: calc(var(--ring-unit) * 3);
}

.relayToggleWrapper__d31 {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.limit__b92 {
  margin-top: calc(var(--ring-unit) * 3);
}
`, "",{"version":3,"sources":["webpack://./src/modules/code-with-me/common/dialogs/cwm-enable-dialog/cwm-enable-dialog.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,uCAAuC;AACzC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,sCAAsC;EACtC,gCAAgC;AAClC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".contentWrapper {\n  width: calc(var(--ring-unit) * 65);\n  min-height: calc(var(--ring-unit) * 34);\n}\n\n.headerNote {\n  padding: 0 calc(var(--ring-unit) * 4);\n}\n\n.globalDisableWarning {\n  margin-top: calc(var(--ring-unit) / 2);\n  color: var(--ring-warning-color);\n}\n\n.confirmGlobalAccessWrapper {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.relayToggleWrapper {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.limit {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `contentWrapper__b23`,
	"headerNote": `headerNote__f42`,
	"globalDisableWarning": `globalDisableWarning__cf8`,
	"confirmGlobalAccessWrapper": `confirmGlobalAccessWrapper__b76`,
	"relayToggleWrapper": `relayToggleWrapper__d31`,
	"limit": `limit__b92`
};
export default ___CSS_LOADER_EXPORT___;
