import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Icon from '@jetbrains/ring-ui/components/icon';
import info from '@jetbrains/icons/info';
import { PopupMessage } from '@common/popup-message/popup-message';
import styles from '@common/badge/specialized-badges/specialized-badges.css';
import { Badge } from '@common/badge';
export function TrialEndedBadge(props) {
    const { tooltipContent, tooltipTitle } = props;
    const badge = (_jsxs(Badge, { variant: Badge.variant.Filled, color: Badge.color.Disabled, size: Badge.size.Md, className: classNames(styles.badge, styles.error), children: ["Trial ended ", tooltipContent && _jsx(Icon, { className: styles.icon, glyph: info })] }));
    if (!tooltipContent) {
        return badge;
    }
    return (_jsx(PopupMessage, { title: tooltipTitle, anchor: badge, children: tooltipContent }));
}
