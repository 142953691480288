import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useCodeWithMeConstituent } from '@app/common/queries/code-with-me';
import { useModalsController, useOpenModal } from '@app/common/modals/hooks';
import { getConstituentDialogFirstStep } from '@modules/constituents/constituent-dialog/constituent-dialog-steps';
import { CwmEnableDialog } from '../../common/dialogs/cwm-enable-dialog/cwm-enable-dialog';
import { CwmDisableDialog } from '../../common/dialogs/cwm-disable-dialog/cwm-disable-dialog';
import { CwmEditSettingsDialog } from '../../common/dialogs/cwm-edit-settings-dialog/cwm-edit-settings-dialog';
import { CodeWithMeDisabled } from './code-with-me-disabled/code-with-me-disabled';
import { CodeWithMeEnabled } from './code-with-me-enabled/code-with-me-enabled';
import { CodeWithMeMisconfigured } from './code-with-me-misconfigured/code-with-me-misconfigured';
export function CodeWithMeConstituent(props) {
    const { className, licenseType, constituentLicense } = props;
    const { data } = useCodeWithMeConstituent();
    const modalsController = useModalsController();
    const handleOpenModal = useCallback(async () => {
        const openEnableModal = modalsController.openModal(CwmEnableDialog, {
            startFromStep: getConstituentDialogFirstStep(constituentLicense, licenseType)
        });
        openEnableModal.promise.then(() => {
            openEnableModal.closeModal();
        });
    }, [constituentLicense, licenseType, modalsController]);
    const openDisableDialog = useOpenModal(CwmDisableDialog, {});
    const openEditDialog = useOpenModal(CwmEditSettingsDialog, {
        initialLimitUsage: (data === null || data === void 0 ? void 0 : data.status) === 'enabled' ? data.data.concurrentHostsLimit : undefined
    });
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled') {
        return (_jsx(CodeWithMeDisabled, { onEnabledClick: handleOpenModal, className: className, trialData: { licenseType, license: constituentLicense } }));
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'configuration_error') {
        return _jsx(CodeWithMeMisconfigured, { className: className, data: data.data });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'enabled') {
        return (_jsx(CodeWithMeEnabled, { data: data.data, onEditClick: openEditDialog, onDisableClick: openDisableDialog, allowEditSettings: constituentLicense.trial.state !== 'ACTIVE' && licenseType === 'POSTPAID', className: className, licenseType: licenseType, license: constituentLicense }));
    }
    return null;
}
