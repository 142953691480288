// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__ec3 {
  display: flex;
}

.appList__d06 + .appSettings__a60 {
  margin-left: calc(var(--ring-unit) * 6);
}

.appList__d06 {
  height: fit-content;
  flex: 0 0 auto;
}

.appSettings__a60 {
  flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/common/user-aggregated-settings-page/user-aggregated-settings-page.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".wrapper {\n  display: flex;\n}\n\n.appList + .appSettings {\n  margin-left: calc(var(--ring-unit) * 6);\n}\n\n.appList {\n  height: fit-content;\n  flex: 0 0 auto;\n}\n\n.appSettings {\n  flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__ec3`,
	"appList": `appList__d06`,
	"appSettings": `appSettings__a60`
};
export default ___CSS_LOADER_EXPORT___;
