import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Header, Content } from '@jetbrains/ring-ui/components/island/island';
import { ListProps } from '@jetbrains/ring-ui/components/popup-menu/popup-menu';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import Select from '@jetbrains/ring-ui/components/select/select';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { usePluginVersions } from '@app/common/queries/plugins';
import CopiedCode from '../../../common/clipboard/copied-code/copied-code';
import { copyToClipboard } from '../../../common/clipboard/copy-to-clipboard';
import RingFieldsList from '../../../common/form/ring-fields-list';
import HelpIcon from '../../../common/util/help-icon';
import styles from './install-plugin-dialog.css';
export default function InstallPluginDialog({ plugin, version, onClose }) {
    var _a;
    const { data: versions } = usePluginVersions(plugin.id);
    const channelOptions = useMemo(() => ((version === null || version === void 0 ? void 0 : version.channels) || []).map(channel => ({
        key: channel.id,
        label: channel.name,
        model: channel
    })), [version]);
    const [selectedChannelOption, setSelectedChannelOption] = useState(channelOptions[0]);
    const versionOptions = useMemo(() => [
        { key: 'last-version', label: 'Last available version' },
        { key: 'separator', rgItemType: ListProps.Type.SEPARATOR }
    ].concat(((versions === null || versions === void 0 ? void 0 : versions.items) || []).map(version => ({
        key: version.id,
        label: version.version,
        version
    }))), [versions]);
    const [selectedVersionOption, setSelectedVersionOption] = useState(versionOptions.filter(it => it.key === (version === null || version === void 0 ? void 0 : version.id))[0] || versionOptions[0]);
    const link = useMemo(() => {
        return [
            `jetbrains://install-plugin?pluginId=${encodeURIComponent(plugin.id)}`,
            (selectedVersionOption === null || selectedVersionOption === void 0 ? void 0 : selectedVersionOption.version)
                ? `version=${encodeURIComponent(selectedVersionOption === null || selectedVersionOption === void 0 ? void 0 : selectedVersionOption.version.version)}`
                : '',
            `channel=${(selectedChannelOption === null || selectedChannelOption === void 0 ? void 0 : selectedChannelOption.label) ? encodeURIComponent(selectedChannelOption.label) : 'Stable'}`
        ]
            .filter(it => !!it)
            .join('&');
    }, [plugin, selectedVersionOption, selectedChannelOption]);
    const copyAndClose = useCallback(async () => {
        await copyToClipboard(link);
        onClose();
    }, [onClose, link]);
    return (_jsxs(Dialog, { show: true, autoFocusFirst: true, label: `Link: ${link}`, "data-test": "copy-plugin-version-instalation-link-dialog", contentClassName: styles.dialog, onCloseAttempt: onClose, children: [_jsx(Header, { children: "Plugin Installation Link" }), _jsx(Content, { children: _jsxs(RingFieldsList, { children: [_jsxs(Text, { children: ["Share this link with the developers in your organization to install the plugin in all compatible IDEs on their machines", ' ', _jsx(Tooltip, { title: _jsxs(_Fragment, { children: [_jsx("div", { children: _jsxs(Text, { children: ["Select the ", _jsx("b", { children: "channel" }), " from which you want to install the plugin."] }) }), _jsx("div", { children: _jsxs(Text, { children: ["If the ", _jsx("b", { children: "version" }), " is not provided, the most recent plugin version will be installed."] }) })] }), children: _jsx(HelpIcon, {}) })] }), versionOptions && !!((_a = versions === null || versions === void 0 ? void 0 : versions.items) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(Select, { selectedLabel: "Version", data: versionOptions, selected: selectedVersionOption, onSelect: it => it && setSelectedVersionOption(it) })), selectedChannelOption && (_jsx(Select, { selectedLabel: "Channel", data: channelOptions, selected: selectedChannelOption, onSelect: it => it && setSelectedChannelOption(it) })), _jsx(CopiedCode, { className: styles.code, text: link })] }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: copyAndClose, children: "Copy and close" }), _jsx(Button, { onClick: onClose, children: "Close" })] })] }));
}
