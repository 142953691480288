import { useMutation, useQuery } from '@tanstack/react-query';
import { changeIdeProvisionerSettings, disableIdeProvisioner, enableIdeProvisioner, getIdePState } from '@api/ide-provisioner';
import { formatApiError } from '@api/errors';
import { getUsersAndTotal } from '@api/users';
import queryClient from '@app/common/queries/query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from '@app/common/queries/formatters/alerts';
import { invalidateAllQueries } from '@common/queries/functions';
import { DEFAULT_POLLING_INTERVAL } from '@common/queries/constants';
export const keys = {
    all: ['idep'],
    status: ['idep', 'idep-enabled'],
    usage: ['idep', 'idep-usage-count']
};
export function useIdeProvisionerConstituent() {
    return useQuery({
        queryKey: keys.status,
        queryFn: getIdePState,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch IDE Provisioner status')),
        refetchInterval: state => {
            return (state === null || state === void 0 ? void 0 : state.status) === 'enabled' && state.data.activeUsersCount === 0
                ? DEFAULT_POLLING_INTERVAL
                : false;
        }
    });
}
export function useIdeProvisionerConstituentUsageCount() {
    return useQuery({
        queryKey: keys.usage,
        queryFn: async () => getUsersAndTotal({ constituentId: 'idep', page: 1, limit: 1 }, undefined),
        select: data => data.total,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch IDE Provisioner usage count'))
    });
}
export function useEnableIdePMutation(onSuccess) {
    return useMutation({
        mutationFn: enableIdeProvisioner,
        onSuccess: async (newIdePState) => {
            queryClient.setQueryData(keys.status, newIdePState);
            await invalidateAllQueries();
            onSuccess(newIdePState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to enable IDE Provisioner'), () => 'IDE Provisioner enabled')
    });
}
export function useChangeIDEPSettingsMutation(onSuccess) {
    return useMutation({
        mutationFn: changeIdeProvisionerSettings,
        onSuccess: async (newIdePState) => {
            queryClient.setQueryData(keys.status, newIdePState);
            await invalidateAllQueries();
            onSuccess(newIdePState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to change IDE Provisioner settings'), () => 'IDE Provisioner settings changed')
    });
}
export function useDisableIdePMutation(onSuccess) {
    return useMutation({
        mutationFn: disableIdeProvisioner,
        onSuccess: async (newIdePState) => {
            queryClient.setQueryData(keys.status, newIdePState);
            await queryClient.invalidateQueries();
            onSuccess();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to disable IDE Provisioner'), () => 'IDE Provisioner disabled')
    });
}
