import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { Outlet, useLoaderData, useNavigate, useRevalidator } from 'react-router-dom';
import timeIcon from '@jetbrains/icons/time';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import Button from '@jetbrains/ring-ui/components/button/button';
import { noop } from 'lodash';
import { licenseVaultApi } from '@jetbrains-license-vault/client/lib';
import { DeactivateUserField } from '@modules/users/user-details/user-edit-form/form-fields/deactivate-user-field';
import confirmUserActiveStatusChange from '@modules/users/user-operations/confirm-user-active-status-change';
import { toLicense } from '@modules/license-vault/licences-table';
import { RouteTab, RouteTabs } from '@common/route-tabs';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { PageTitle } from '@common/page-layout/page-title';
import { useActiveStatusUserMutation, useRevokeUserToken } from '@common/queries/users';
import auth from '@common/auth/auth';
import { useLvConstituentState } from '@common/queries/license-vault';
import styles from './user-details-page.css';
export function UserDetailsPage() {
    var _a;
    const user = useLoaderData();
    const { revalidate } = useRevalidator();
    const currentUser = auth.getUserProfile();
    const navigate = useNavigate();
    const { data: lvConstituentState, isLoading: isLVStateLoading } = useLvConstituentState();
    const { data: licensesDto } = licenseVaultApi.users.useLicenses();
    const licenses = useMemo(() => {
        return licensesDto ? licensesDto.flatMap(toLicense) : [];
    }, [licensesDto]);
    const licenseVaultTabVisible = !isLVStateLoading && (lvConstituentState === null || lvConstituentState === void 0 ? void 0 : lvConstituentState.status) === 'enabled' && user.id === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id);
    const hasSamlEnabled = !!auth.getFeatures().enableSaml;
    const { mutateAsync: revokeTokenMutation, isLoading: isRevoking } = useRevokeUserToken((_a = user.id) !== null && _a !== void 0 ? _a : '');
    const userActiveStatusMutation = useActiveStatusUserMutation();
    const handleRevokeUserClick = useCallback(() => {
        revokeTokenMutation().catch(noop);
    }, [revokeTokenMutation]);
    const handleNavigate = useCallback(() => navigate('audit-logs'), [navigate]);
    const confirmActiveStatusChange = useCallback((newValue) => {
        return confirmUserActiveStatusChange(user.username, newValue)
            .then(async () => {
            const updatedUser = await userActiveStatusMutation.mutateAsync({
                id: user.id,
                isActive: newValue,
                version: user.version
            });
            revalidate();
            return updatedUser;
        })
            .catch(noop);
    }, [revalidate, userActiveStatusMutation, user]);
    return (_jsxs("div", { ...assignTestId('user-details-page-content'), children: [_jsx(PageTitle, { title: user.username, className: styles.title, breadcrumbs: [{ title: 'Users', href: '/users' }], rightPanel: _jsxs(Tooltip, { title: "Change history", ...assignTestId('user-history-tooltip'), children: [_jsx(Button, { icon: timeIcon, onClick: handleNavigate }), hasSamlEnabled && (_jsx(Button, { danger: true, onClick: handleRevokeUserClick, loader: isRevoking, children: "Revoke user tokens" }))] }), children: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) !== user.id && (_jsx(DeactivateUserField, { isActive: user.isActive, onChange: confirmActiveStatusChange, disabled: user.isReadOnly })) }), _jsxs(RouteTabs, { className: styles.tabs, ...assignTestId('user-details-page-tabs'), children: [_jsx(RouteTab, { path: "", title: "General", titleProps: assignTestId('general') }), _jsx(RouteTab, { path: "aggregated-settings", title: "Aggregated Settings", titleProps: assignTestId('aggregated-settings') }), licenseVaultTabVisible && (_jsx(RouteTab, { path: "licenses", title: _jsxs("p", { children: ["Licenses ", _jsx("p", { className: styles.info, children: licenses.length })] }), titleProps: assignTestId('licenses') }))] }), _jsx(Outlet, {})] }));
}
