import { useState, useEffect, useMemo, useCallback } from 'react';
import { useRequestConstituentTrialMutation } from '@common/queries/constituents-common';
import { useTbeLicense } from '@common/queries/tbe-license';
export const useRequestConstituentTrial = (constituentId, onSuccess) => {
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync, isSuccess: isMutationSuccess, isError: isMutationError } = useRequestConstituentTrialMutation(constituentId);
    const { data: licenseData, isError: isLicenseError, isRefetching } = useTbeLicense();
    const constituentData = useMemo(() => licenseData === null || licenseData === void 0 ? void 0 : licenseData.constituents.find(c => c.id === constituentId), [constituentId, licenseData === null || licenseData === void 0 ? void 0 : licenseData.constituents]);
    const handleMutate = useCallback(async () => {
        setIsLoading(true);
        try {
            await mutateAsync();
        }
        catch {
            setIsLoading(false);
        }
    }, [mutateAsync]);
    useEffect(() => {
        if (isLicenseError || isMutationError) {
            setIsLoading(false);
        }
    }, [isLicenseError, isMutationError]);
    useEffect(() => {
        if (isMutationSuccess && (licenseData === null || licenseData === void 0 ? void 0 : licenseData.status) === 'ACTIVE' && !isRefetching) {
            setIsLoading(false);
            onSuccess();
        }
    }, [isMutationSuccess, isRefetching, licenseData === null || licenseData === void 0 ? void 0 : licenseData.status, onSuccess]);
    return {
        mutate: handleMutate,
        isLoading,
        constituentData,
        isError: isLicenseError || isMutationError
    };
};
