import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './badge.css';
function Badge(props) {
    const { variant, children, color, size, inline, className, ...rest } = props;
    return (_jsx("div", { className: classNames(styles.wrapper, className, { [styles.inline]: inline }, {
            [styles.filled]: variant === 'filled',
            [styles.outline]: variant === 'outline',
            [styles.fillContrast]: variant === 'filled-contrast',
            [styles.ghost]: variant === 'ghost'
        }, {
            [styles.primary]: color === 'primary',
            [styles.error]: color === 'error',
            [styles.success]: color === 'success',
            [styles.warning]: color === 'warning',
            [styles.disabled]: color === 'disabled'
        }, { [styles.lg]: size === 'lg', [styles.sm]: size === 'sm', [styles.md]: size === 'md' }), ...assignTestId('badge', rest), children: children }));
}
var BadgeSize;
(function (BadgeSize) {
    BadgeSize["Lg"] = "lg";
    BadgeSize["Md"] = "md";
    BadgeSize["Sm"] = "sm";
})(BadgeSize || (BadgeSize = {}));
var BadgeVariant;
(function (BadgeVariant) {
    BadgeVariant["Filled"] = "filled";
    BadgeVariant["Filled_Contrast"] = "filled-contrast";
    BadgeVariant["Outline"] = "outline";
    BadgeVariant["Ghost"] = "ghost";
})(BadgeVariant || (BadgeVariant = {}));
var BadgeColor;
(function (BadgeColor) {
    BadgeColor["Primary"] = "primary";
    BadgeColor["Error"] = "error";
    BadgeColor["Success"] = "success";
    BadgeColor["Warning"] = "warning";
    BadgeColor["Disabled"] = "disabled";
})(BadgeColor || (BadgeColor = {}));
Badge.variant = BadgeVariant;
Badge.color = BadgeColor;
Badge.size = BadgeSize;
export { Badge };
