import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import settings from '@jetbrains/icons/settings';
import trash from '@jetbrains/icons/trash';
import classNames from 'classnames';
import Icon from '@jetbrains/ring-ui/components/icon';
import addIcon from '@jetbrains/icons/add';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
import { useOpenModal } from '@common/modals/hooks';
import { InformationBanner } from '@common/information-banner/information-banner';
import { BasicTableView } from '@common/tables/basic-table-view';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import tableStyles from '../../styles.css';
import styles from './provider-models.css';
export function ProviderModels(props) {
    const { models = [], onModelsChange, modelDialogComponent: DialogComponent, columns: columnsExt, modelConfigurations, isReadonlyModel, headless, errorMsg } = props;
    const notYetAddedConfigurations = useMemo(() => modelConfigurations.filter(conf => models.every(model => model.id !== conf.id)), [modelConfigurations, models]);
    const openModal = useOpenModal(DialogComponent, {
        model: undefined,
        type: modelConfigurations[0].id
    });
    const handleAddModel = useCallback(async (type) => {
        const newModel = await openModal({ type });
        if (newModel) {
            onModelsChange([...models, newModel]);
        }
    }, [openModal, models, onModelsChange]);
    const editModel = useCallback(async (model) => {
        const modelIndex = models.findIndex(m => m.id === model.id);
        const updatedModel = await openModal({ model, type: model.id });
        if (updatedModel) {
            const updatedModels = [...models];
            updatedModels[modelIndex] = updatedModel;
            onModelsChange(updatedModels);
        }
    }, [openModal, models, onModelsChange]);
    const removeModel = useCallback((model) => {
        onModelsChange(models.filter(m => m.id !== model.id));
    }, [models, onModelsChange]);
    const columns = useMemo(() => {
        return columnsExt
            .map(column => {
            // fixme: Remove this when all tables will be reworked (IDES-5991)
            const { maxWidth } = column;
            if (maxWidth) {
                return {
                    ...column,
                    getValue: (model) => {
                        var _a, _b;
                        return (_jsx("div", { style: widthToCssProperties(maxWidth), className: styles.limitedWidthColumn, children: (_b = (_a = column.getValue) === null || _a === void 0 ? void 0 : _a.call(column, model, column)) !== null && _b !== void 0 ? _b : model[column.id] }));
                    }
                };
            }
            return column;
        })
            .concat({
            id: 'controls',
            getValue: (model) => {
                if (isReadonlyModel === null || isReadonlyModel === void 0 ? void 0 : isReadonlyModel(model)) {
                    return undefined;
                }
                return (_jsx(TableControls, { onEditClick: () => editModel(model), onRemoveClick: () => removeModel(model) }));
            }
        });
    }, [editModel, removeModel, columnsExt, isReadonlyModel]);
    const dropdownMenuItems = useMemo(() => notYetAddedConfigurations.map(conf => ({
        label: conf.label,
        onClick: () => handleAddModel(conf.id),
        ...assignTestId('add-model-dropdown-item', conf.id)
    })), [notYetAddedConfigurations, handleAddModel]);
    return (_jsxs("section", { className: styles.wrapper, ...assignTestId('provider-models'), children: [!headless && _jsx("header", { className: styles.header, children: "Models" }), errorMsg && (_jsx(InformationBanner, { className: styles.msg, variant: "danger", children: errorMsg })), models.length === 0 && !errorMsg && (_jsx(InformationBanner, { className: styles.msg, variant: "neutral", children: "No models configured yet" })), !!models.length && (_jsx(BasicTableView, { ...assignTestId('models-table'), tableWrapperClassName: classNames(styles.table, tableStyles.modelsTable, tableStyles.full), columns: columns, data: models, selectable: false, size: "FULL" })), !!notYetAddedConfigurations.length && (_jsxs("div", { className: styles.actions, children: [notYetAddedConfigurations.length > 1 && (_jsx(DropdownMenu, { ...assignTestId('add-model-dropdown-menu'), anchor: _jsxs(Button, { ...assignTestId('add-model-dropdown-anchor'), text: true, inline: true, children: [_jsx(Icon, { glyph: addIcon }), _jsx("span", { className: styles.addBtnText, children: "Add Model" })] }), data: dropdownMenuItems })), notYetAddedConfigurations.length === 1 && (_jsxs(Button, { ...assignTestId('add-model-btn'), text: true, inline: true, onClick: () => handleAddModel(notYetAddedConfigurations[0].id), children: [_jsx(Icon, { glyph: addIcon }), _jsx("span", { className: styles.addBtnText, children: "Add Model" })] }))] }))] }));
}
function TableControls(props) {
    const { onEditClick, onRemoveClick } = props;
    return (_jsxs("div", { className: styles.tableControls, children: [_jsx(Button, { className: classNames(styles.control, styles.edit), icon: settings, onClick: onEditClick, ...assignTestId('edit-model') }), _jsx(Button, { className: classNames(styles.control, styles.remove), icon: trash, onClick: onRemoveClick, ...assignTestId('remove-model') })] }));
}
function widthToCssProperties(width) {
    return { '--column-width': `${width}px` };
}
