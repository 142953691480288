import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import classNames from 'classnames';
import Button from '@jetbrains/ring-ui/components/button/button';
import trash from '@jetbrains/icons/trash';
import pencil from '@jetbrains/icons/pencil';
import { getIdeModeName } from '@modules/remote-development/get-ide-mode-name';
import { ParagraphLoader } from '@common/loaders/paragraph-loader';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import auth from '@common/auth/auth';
import styles from './filter.css';
export const Filter = memo(function Filter(props) {
    const { filter, onEditFilterClick, onRemoveClick, productsMap, target, editable, loading, className } = props;
    const handleEditClick = useCallback(() => onEditFilterClick(filter), [onEditFilterClick, filter]);
    const handleRemoveClick = useCallback(() => onRemoveClick(filter), [onRemoveClick, filter]);
    return (_jsxs("div", { className: classNames(styles.wrapper, className), ...assignTestId('filter'), children: [loading ? (_jsx(ParagraphLoader, { linesNumber: 1 })) : (_jsx(FilterInfo, { filter: filter, target: target, productsMap: productsMap })), _jsxs("div", { className: styles.actions, children: [editable && (_jsx(Button, { className: styles.action, short: true, icon: pencil, onClick: handleEditClick, ...assignTestId('edit-filter') })), _jsx(Button, { className: classNames(styles.action, styles.remove), icon: trash, onClick: handleRemoveClick, ...assignTestId('remove-filter') })] })] }));
});
function FilterInfo(props) {
    var _a, _b, _c, _d, _e;
    const { remoteDevelopmentUi } = auth.getFeatures();
    const { filter, target, productsMap } = props;
    const { type: filterType } = filter;
    return (_jsxs("section", { className: classNames(styles.filterInfo, {
            [styles.exclusion]: filterType === 'exclude' || filterType === 'exclude-all'
        }), children: [_jsxs("p", { className: styles.mainInfo, children: [filterDescription[target][filterType], !!((_a = filter.products) === null || _a === void 0 ? void 0 : _a.length) && (_jsx("span", { className: styles.products, children: filter.products.map(product => { var _a, _b; return (_b = (_a = productsMap.get(product)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : product; }).join(', ') }))] }), !!((_b = filter.builds) === null || _b === void 0 ? void 0 : _b.length) && (_jsx(FilterSecondaryInfo, { type: "build", label: "Builds", values: filter.builds })), !!((_c = filter.os) === null || _c === void 0 ? void 0 : _c.length) && _jsx(FilterSecondaryInfo, { type: "os", label: "OS", values: filter.os }), !!((_d = filter.arch) === null || _d === void 0 ? void 0 : _d.length) && (_jsx(FilterSecondaryInfo, { type: "arch", label: "Arch", values: filter.arch })), !!filter.quality && (_jsx(FilterSecondaryInfo, { type: "quality", label: "Quality", values: [filter.quality.name] })), !!((_e = filter.ideMode) === null || _e === void 0 ? void 0 : _e.length) && remoteDevelopmentUi && (_jsx(FilterSecondaryInfo, { type: "ide-modes", label: "Dev environments", values: filter.ideMode.map(env => getIdeModeName(env)) }))] }));
}
function FilterSecondaryInfo(props) {
    const { label, values, type } = props;
    const displayedValue = values.join(', ');
    return (_jsxs("p", { className: styles.filterSecondaryInfo, ...assignTestId(`filter-secondary-info-${type}`), children: [_jsxs("span", { className: styles.label, children: [label, ":"] }), _jsx("span", { className: styles.value, title: displayedValue, children: displayedValue })] }));
}
export const filterDescription = {
    restrictions: {
        'include-all': 'Restrict all applications',
        'exclude-all': "Don't restrict all applications",
        include: 'Restrict',
        exclude: "Don't restrict"
    },
    applications: {
        'include-all': 'Include all applications',
        include: 'Include',
        'exclude-all': 'Exclude all applications',
        exclude: 'Exclude'
    },
    settings: {
        'include-all': 'Include all applications enabled in the profile',
        include: 'Include',
        'exclude-all': 'Exclude all applications enabled in the profile',
        exclude: 'Exclude'
    }
};
