import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import closeIcon from '@jetbrains/icons/close';
import { isEqual, noop } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { roleTexts } from '@api/users';
import { useUsersByProfileId } from '@app/common/queries/profiles';
import { useUnAssignUsersMutation } from '@app/common/queries/users';
import { withSortableColumnFlag } from '@app/common/tables/table-columns';
import NavigationLink from '@app/common/navigation/navigation-link';
import { TableActionButton } from '@app/common/tables/table-action-button';
import { EnumerableTextElements } from '@app/common/text-formattings/enumerable-text-elements';
import { useModalsController } from '@app/common/modals';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import { Badge } from '@common/badge';
import { UsersFilters } from './filters/users-filters';
import { confirmUserRemoval } from './confirm-user-removal';
import UnassignUserWithGroupDialog from './assign-dialog/unassign-user-with-group-dialog';
import styles from './table-list-body.css';
function UsersListBody({ profileId, profileTitle, urlParams, setUrlParams }) {
    const navigate = useNavigate();
    const unassignUserMutation = useUnAssignUsersMutation();
    const modalsController = useModalsController();
    const { data, isFetching } = useUsersByProfileId(profileId, urlParams, !!profileId);
    const users = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.items) !== null && _a !== void 0 ? _a : []; }, [data]);
    const total = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0; }, [data]);
    const onEnterUser = useCallback((user) => {
        navigate(`/users/${user.id}`);
    }, [navigate]);
    const onFiltersChange = useCallback((newFilters) => {
        setUrlParams({
            ...urlParams,
            ...newFilters,
            page: isEqual(newFilters, urlParams) ? urlParams.page : 1
        });
    }, [setUrlParams, urlParams]);
    const handlePageChangeOnUserRemove = useCallback(() => {
        if (urlParams.page === 1 || users.length !== 1) {
            return;
        }
        setUrlParams({
            ...urlParams,
            page: urlParams.page - 1
        });
    }, [setUrlParams, urlParams, users.length]);
    const handleRemoveUser = useCallback((item, groups) => {
        if (groups.length > 0) {
            const assignUserDialog = modalsController.openModal(UnassignUserWithGroupDialog, {
                profileName: profileTitle,
                profileId,
                userName: item.username,
                onReject() {
                    assignUserDialog.closeModal();
                },
                groups,
                userId: item.id
            });
            return;
        }
        confirmUserRemoval(item, profileTitle)
            .then(() => unassignUserMutation
            .mutateAsync({
            profileId,
            usersList: [item]
        })
            .then(handlePageChangeOnUserRemove)
            .catch(noop))
            .catch(hideConfirm);
    }, [profileTitle, modalsController, handlePageChangeOnUserRemove, profileId, unassignUserMutation]);
    const columns = useMemo(() => withSortableColumnFlag([
        {
            id: 'username',
            title: 'Name',
            getValue: getUsername
        },
        {
            id: 'email',
            title: 'Email'
        },
        {
            id: 'role',
            title: 'Role',
            getValue: user => roleTexts[user.role] || ''
        },
        {
            id: 'assignedFrom',
            title: 'Assigned from',
            getValue: getGroups
        },
        {
            id: 'actionButtons',
            getValue: ({ id, username, groups }) => getTableActions(() => handleRemoveUser({ id, username }, groups))
        }
    ], usersSortableColumns), [handleRemoveUser]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.actionButtons, children: _jsx(UsersFilters, { filters: { ...urlParams }, onFiltersChange: onFiltersChange }) }), _jsx(BasicSelectableTable, { data: users, loading: isFetching, columns: columns, onItemEnter: onEnterUser, renderEmpty: () => (isFetching ? 'Loading...' : 'No users found'), sortKey: urlParams.sortKey, sortOrder: urlParams.sortOrder === 'ASC', onSort: event => setUrlParams({
                    ...urlParams,
                    sortKey: event.column.id,
                    sortOrder: event.order ? 'ASC' : 'DESC',
                    page: 1
                }), selectable: false, pagerProps: {
                    total,
                    currentPage: urlParams.page,
                    pageSize: DEFAULT_TABLE_PAGE_SIZE,
                    onPageChange: newPage => {
                        setUrlParams({ ...urlParams, page: newPage });
                    },
                    disablePageSizeSelector: true
                } })] }));
}
function getUsername(user) {
    return (_jsxs("div", { className: styles.usernameBadge, children: [_jsx(NavigationLink, { href: `/users/${user.id}`, children: user.username }), user.isActive ? null : (_jsx(Badge, { variant: Badge.variant.Filled, color: Badge.color.Disabled, size: Badge.size.Md, children: "Deactivated" }))] }));
}
function getGroups(user) {
    if (user.groups.length === 0) {
        return _jsx(Text, { children: "Directly" });
    }
    return (_jsx(EnumerableTextElements, { items: user.groups, getKey: group => `user-${user.id}-group-${group.id}`, getValue: group => (_jsx(NavigationLink, { href: `/users?groupId=${group.id}`, children: group.name })), maxVisibleElems: 2 }));
}
function getTableActions(callback) {
    return (_jsx(TableActionButton, { title: "Remove user from the Profile", icon: closeIcon, action: callback, danger: true }));
}
export const usersSortableColumns = {
    username: 'username',
    email: 'email',
    role: 'role'
};
export default UsersListBody;
