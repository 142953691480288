import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import classnames from 'classnames';
import copyIcon from '@jetbrains/icons/copy';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { copyToClipboard } from '../copy-to-clipboard';
import styles from './copied-text.css';
function CopiedText({ text, className, getValue = async () => text, includeIcon = true, ...restProps }) {
    const copy = useCallback(async () => {
        const textForCopy = await Promise.resolve(getValue()).catch(() => {
            alert.error('Failed to get value for copying into clipboard');
            return '';
        });
        if (textForCopy) {
            await copyToClipboard(textForCopy);
        }
    }, [getValue]);
    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            copy();
        }
    };
    return (_jsxs("span", { role: "button", tabIndex: 0, className: classnames(styles.copyButton, className), onClick: copy, onKeyDown: onKeyDown, title: "Copy to clipboard", ...restProps, children: [_jsx("span", { children: text }), includeIcon && _jsx(Icon, { glyph: copyIcon, className: styles.icon, title: "Copy to clipboard" })] }));
}
export default CopiedText;
