import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Input, { Size as InputSize } from '@jetbrains/ring-ui/components/input/input';
import { BedrockAiModelsTable } from '@modules/ai/common/forms/bedrock-ai-provider-form/bedrock-ai-models-table';
import RingFieldsList from '@common/form/ring-fields-list';
import { Required } from '@common/form/important-asterisk';
import { assignTestId } from '@common/infra/tests/assign-test-id';
const TOKEN_EXISTENCE_INDICATOR = '⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱';
export function BedrockAiProviderForm({ form }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { setValue, watch, formState: { errors } } = form;
    const secretKey = watch('provider.bedrock.secretKey');
    return (_jsxs(RingFieldsList, { children: [_jsx(Input, { ...assignTestId('bedrock-region-input'), value: watch('provider.bedrock.region'), label: Required('Region'), onChange: val => setValue('provider.bedrock.region', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), size: InputSize.L, error: (_c = (_b = (_a = errors.provider) === null || _a === void 0 ? void 0 : _a.bedrock) === null || _b === void 0 ? void 0 : _b.region) === null || _c === void 0 ? void 0 : _c.message }), _jsx(Input, { ...assignTestId('bedrock-access-key-input'), value: watch('provider.bedrock.accessKey'), label: Required('Access key'), onChange: val => setValue('provider.bedrock.accessKey', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), size: InputSize.L, error: (_f = (_e = (_d = errors.provider) === null || _d === void 0 ? void 0 : _d.bedrock) === null || _e === void 0 ? void 0 : _e.accessKey) === null || _f === void 0 ? void 0 : _f.message }), _jsx(Input, { ...assignTestId('bedrock-secret-key-input'), value: watch('provider.bedrock.secretKey'), label: Required('Secret key'), placeholder: secretKey === null ? TOKEN_EXISTENCE_INDICATOR : '', onChange: val => setValue('provider.bedrock.secretKey', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), type: "password", size: InputSize.L, error: (_j = (_h = (_g = errors.provider) === null || _g === void 0 ? void 0 : _g.bedrock) === null || _h === void 0 ? void 0 : _h.secretKey) === null || _j === void 0 ? void 0 : _j.message }), _jsx(BedrockAiModelsTable, { models: watch('provider.bedrock.models') })] }));
}
