import { apiClientManager } from '@common/api';
export async function fetchPluginDownloads(pluginId, period) {
    return apiClientManager
        .getClient()
        .get(`api/usage-analytics/plugins/${encodeURIComponent(pluginId)}/downloads?period=${period}`)
        .then(r => r.data);
}
export async function fetchPluginDownloadsAnalytics(period) {
    return apiClientManager
        .getClient()
        .get(`api/usage-analytics/plugins/downloads?period=${period}`)
        .then(r => ({ entries: r.data }));
}
