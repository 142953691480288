import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import TagsList from '@jetbrains/ring-ui/components/tags-list/tags-list';
import Text from '@jetbrains/ring-ui/components/text/text';
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown';
import Popup from '@jetbrains/ring-ui/components/popup/popup';
import { Directions } from '@jetbrains/ring-ui/components/popup/popup.consts';
import { getIdeModeName } from '@modules/remote-development/get-ide-mode-name';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { NestedCheckbox, getUpdatedSelection } from '@common/nested-checkbox';
import { Badge } from '@common/badge';
import { getEnvironmentOptions } from './get-environment-options';
import styles from './remote-environment-field.css';
function RemoteEnvironmentField() {
    const { setValue, watch } = useFormContext();
    const environments = watch('ideMode');
    const dataListOptions = useMemo(() => {
        return getEnvironmentOptions().map(addSelectedFlag);
        function addSelectedFlag({ items, ...rest }) {
            return {
                items: items.map(addSelectedFlag),
                checked: (environments || []).some(it => it === rest.ideMode),
                ...rest
            };
        }
    }, [environments]);
    const enabledItems = useMemo(() => getEnabledItems(dataListOptions), [dataListOptions]);
    const handleSelection = useCallback((key) => {
        const result = getUpdatedSelection(key, environments, dataListOptions, 'ideMode').filter(it => enabledItems.includes(it));
        setValue('ideMode', result, { shouldDirty: true });
    }, [dataListOptions, enabledItems, environments, setValue]);
    const onRemoveTag = useCallback((tag) => (e) => {
        e.stopPropagation();
        if (typeof tag.key === 'string') {
            handleSelection(tag.key);
        }
    }, [handleSelection]);
    const dropdownAnchor = useCallback((anchorProps) => (_jsx("div", { ...anchorProps, className: styles.tagsContainer, children: !environments || !environments.length ? (_jsx(Text, { info: true, className: styles.placeholder, children: "All" })) : (_jsx(TagsList, { id: "environmentsField", tags: environments.map(env => ({ key: env, label: getIdeModeName(env) })), handleRemove: onRemoveTag })) })), [environments, onRemoveTag]);
    const checkboxSideComponent = (envOption) => {
        return (_jsxs("div", { className: styles.titleContainer, children: [_jsx(Text, { children: envOption.label }), envOption.disabled && (_jsx(Badge, { variant: Badge.variant.Filled, color: Badge.color.Disabled, size: Badge.size.Lg, children: "Coming soon" }))] }));
    };
    return (_jsxs("div", { ...assignTestId('environment-filter-field'), children: [_jsx("p", { className: styles.label, children: "Dev Environment" }), _jsx(Dropdown, { anchor: dropdownAnchor, className: styles.dropdownContainer, children: _jsx(Popup, { className: styles.popupContainer, directions: [Directions.BOTTOM_CENTER], children: dataListOptions.map(env => (_jsx(NestedCheckbox, { item: env, onToggle: env => handleSelection(env.ideMode), hasCheckedParent: false, renderLabel: checkboxSideComponent, assignDataTest: item => item.ideMode }))) }) })] }));
}
export default RemoteEnvironmentField;
function getEnabledItems(items) {
    return items.flatMap(item => {
        var _a;
        const childItems = ((_a = item.items) === null || _a === void 0 ? void 0 : _a.length) ? getEnabledItems(item.items) : [];
        return item.disabled ? childItems : [item.ideMode, ...childItems];
    });
}
