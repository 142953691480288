import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { AiLogo } from '@modules/ai/common/ai-logo/ai-logo';
import { ConstituentDialog } from '@modules/constituents/constituent-dialog';
import { AiTrialContent } from '@modules/ai/common/dialogs/ai-settings-dialog/ai-trial-content';
import { ConstituentDialogSteps } from '@modules/constituents/constituent-dialog/constituent-dialog-steps';
import { getLimitLockType } from '@modules/constituents/constituent-dialog/limit-assigment-editor/get-limit-lock-type';
import { getTrialMaxUsage } from '@modules/constituents/constituent-dialog/limit-assigment-editor/get-trial-max-usage';
import { useTbeLicense } from '@common/queries/tbe-license';
import { AiSettingsDialogBase } from './ai-settings-dialog-base';
import styles from './ai-settings-dialog.css';
export function AiEnableDialog(props) {
    var _a;
    const { onCloseAttempt, startFromStep } = props;
    const [step, setStep] = useState(startFromStep);
    const { data: licenseData } = useTbeLicense();
    const constituentTrialData = (_a = licenseData === null || licenseData === void 0 ? void 0 : licenseData.constituents.find(c => c.id === 'ai')) === null || _a === void 0 ? void 0 : _a.trial;
    const lockLimitType = getLimitLockType(licenseData === null || licenseData === void 0 ? void 0 : licenseData.plan, constituentTrialData === null || constituentTrialData === void 0 ? void 0 : constituentTrialData.state);
    const maxUsage = getTrialMaxUsage(constituentTrialData);
    const handleStepChange = () => {
        setStep(prev => prev + 1);
    };
    return (_jsxs(ConstituentDialog, { title: "Enable AI Enterprise", titleLogo: _jsx(AiLogo, {}), children: [step === ConstituentDialogSteps.TRIAL && (_jsx(AiTrialContent, { constituentData: (constituentTrialData === null || constituentTrialData === void 0 ? void 0 : constituentTrialData.state) === 'AVAILABLE' ? constituentTrialData : undefined, onClose: () => onCloseAttempt(undefined), changeStep: handleStepChange, contentClassName: styles.contentWrapper })), step === ConstituentDialogSteps.FORM && (_jsx(AiSettingsDialogBase, { ...props, onClose: () => onCloseAttempt(undefined), contentClassName: styles.contentWrapper, initialLimitUsage: maxUsage, limitLockType: lockLimitType }))] }));
}
