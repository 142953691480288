import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { ConstituentDisabledWidget } from '@modules/constituents/widget/constituent-disabled-widget';
import { getConstituentDialogFirstStep } from '@modules/constituents/constituent-dialog/constituent-dialog-steps';
import { useModalsController } from '@common/modals/hooks';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { LvEnableDialog } from '../../../common/dialogs/lv-enable-dialog/lv-enable-dialog';
export function LvDisabledNoTrialWidget(props) {
    const { licenseType, constituentLicense } = props;
    const modalsController = useModalsController();
    const handleOpenModal = useCallback(async () => {
        const openEnableModal = modalsController.openModal(LvEnableDialog, {
            startFromStep: getConstituentDialogFirstStep(constituentLicense, licenseType)
        });
        openEnableModal.promise.then(() => {
            openEnableModal.closeModal();
        });
    }, [constituentLicense, licenseType, modalsController]);
    return (_jsx(ConstituentDisabledWidget, { ...props, ...assignTestId('widget-license-vault'), onEnableClick: handleOpenModal, children: "Seamlessly distribute and manage JetBrains IDE licenses with features like configurable access rules, real-time usage tracking, and secure access with single sign-on" }));
}
