import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { getConstituentDialogFirstStep } from '@modules/constituents/constituent-dialog/constituent-dialog-steps';
import { useIdeProvisionerConstituent } from '@common/queries/ide-provisioner';
import { useModalsController, useOpenModal } from '@common/modals/hooks';
import { IdePEnableDialog } from '../../common/dialogs/idep-settings-dialog/idep-enable-dialog';
import { IdePChangeSettingsDialog } from '../../common/dialogs/idep-settings-dialog/idep-change-settings-dialog';
import { IdePDisableDialog } from '../../common/dialogs/idep-disable-dialog/idep-disable-dialog';
import { IdeProvisionerEnabled } from './ide-provisioner-enabled/ide-provisioner-enabled';
import { IdeProvisionerDisabled } from './ide-provisioner-disabled/ide-provisioner-disabled';
export function IdeProvisionerConstituent(props) {
    const { className, licenseType, constituentLicense } = props;
    const { data } = useIdeProvisionerConstituent();
    const modalsController = useModalsController();
    const openDisableModal = useOpenModal(IdePDisableDialog, {});
    const handleOpenModal = useCallback(async () => {
        const openEnableModal = modalsController.openModal(IdePEnableDialog, {
            startFromStep: getConstituentDialogFirstStep(constituentLicense, licenseType)
        });
        openEnableModal.promise.then(() => {
            openEnableModal.closeModal();
        });
    }, [constituentLicense, licenseType, modalsController]);
    const openChangeSettingsModal = useOpenModal(IdePChangeSettingsDialog, {
        initialLimitUsage: (data === null || data === void 0 ? void 0 : data.status) === 'enabled' ? data.data.assignedUsersLimit : undefined
    });
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled') {
        return (_jsx(IdeProvisionerDisabled, { className: className, onEnabledClick: handleOpenModal, trialData: { licenseType, license: constituentLicense } }));
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'enabled') {
        return (_jsx(IdeProvisionerEnabled, { onDisableClick: openDisableModal, onEditClick: openChangeSettingsModal, allowEditing: constituentLicense.trial.state !== 'ACTIVE' && licenseType === 'POSTPAID', data: data.data, className: className, licenseType: licenseType, license: constituentLicense }));
    }
    return null;
}
