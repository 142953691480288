import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { isNil, noop } from 'lodash';
import { useEnableLvConstituent } from '@app/common/queries/license-vault';
import { LimitAssigmentEditor } from '@modules/constituents/constituent-dialog';
import styles from './lv-enable-dialog.css';
export function LvEnableDialogBase(props) {
    const { onClose, showPostpaidHint, limit, contentClassName } = props;
    const { mutate: enable, isLoading } = useEnableLvConstituent(onClose);
    const handleEnable = useCallback(() => {
        enable();
    }, [enable]);
    const handleClose = useCallback(() => onClose(), [onClose]);
    return (_jsxs(_Fragment, { children: [_jsxs(Content, { className: contentClassName, children: [_jsx("p", { className: styles.paragraph, children: "License Vault automates the licensing of JetBrains tools in your organization." }), _jsx("p", { className: styles.paragraph, children: "It can distribute your prepaid JetBrains licenses and also automatically issue additional postpaid licenses when your prepaid ones run out." }), showPostpaidHint && (_jsxs("p", { className: styles.paragraph, children: ["By default, you\u2019ll have an unlimited number of postpaid licenses available.", _jsx("br", {}), "You\u2019ll be able to set your postpaid license limits once License Vault is enabled."] })), !isNil(limit) && (_jsx(LimitAssigmentEditor, { className: styles.limit, toggleText: "", limit: limit, readonly: true, onLimitChange: noop, limitEntity: "licenses" }))] }), _jsxs(Panel, { children: [_jsx(Button, { loader: isLoading, onClick: handleEnable, primary: true, "data-test": "dialog-confirm-button", children: "Enable License Vault" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
