import { useMutation, useQuery } from '@tanstack/react-query';
import { endOfToday, startOfMonth } from 'date-fns';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { getConstituentUsageStatistics } from '@api/dashboard';
import { invalidateAllQueries } from '@common/queries/functions';
import { DEFAULT_POLLING_INTERVAL } from '@common/queries/constants';
import { changeCodeWithMeSettings, disableCodeWithMe, enableCodeWithMe, fetchCodeWithMeOneTimeToken, getCodeWithMeState } from '../../api/code-with-me';
import { formatApiError } from '../../api/errors';
import queryClient from './query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from './formatters/alerts';
export const keys = {
    all: ['cwm'],
    status: ['cwm', 'cwm-enabled'],
    concurrentSessions: ['cwm', 'cwm-monthly-concurrent-sessions'],
    token: (sessionId) => ['cwm', 'cwm-token', sessionId]
};
export function fetchCWMOneTimeToken(sessionId) {
    return queryClient.fetchQuery({
        queryKey: keys.token(sessionId),
        queryFn: () => fetchCodeWithMeOneTimeToken(sessionId),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch download link'))
    });
}
export function useCodeWithMeConstituent() {
    return useQuery({
        queryKey: keys.status,
        queryFn: getCodeWithMeState,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch Code With Me status')),
        refetchInterval: state => {
            return (state === null || state === void 0 ? void 0 : state.status) === 'enabled' && state.data.activeUsers === 0
                ? DEFAULT_POLLING_INTERVAL
                : false;
        }
    });
}
const CURRENT_MONTH_TIMEFRAME = {
    from: startOfMonth(new Date()),
    to: endOfToday()
};
export function useCodeWithMeMonthlyConcurrentSessions() {
    return useQuery({
        queryKey: keys.concurrentSessions,
        queryFn: () => getConstituentUsageStatistics('cwm', CURRENT_MONTH_TIMEFRAME),
        select: data => {
            return (data !== null && data !== void 0 ? data : []).reduce((acc, item) => Math.max(acc, item.usage), 0);
        },
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch Code With Me monthly concurrent sessions'))
    });
}
export function useCwmEnableMutation(onSuccess) {
    return useMutation({
        mutationFn: enableCodeWithMe,
        onSuccess: async (newCwmEnabledState) => {
            queryClient.setQueryData(keys.status, newCwmEnabledState);
            await invalidateAllQueries();
            onSuccess(newCwmEnabledState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to enable Code With Me'), data => {
            if (data.status === 'configuration_error') {
                alert.error(data.data.title);
                return null;
            }
            return 'Code With Me enabled';
        })
    });
}
export function useCwmChangeSettingsMutation(onSuccess) {
    return useMutation({
        mutationFn: changeCodeWithMeSettings,
        onSuccess: async (newCwmEnabledState) => {
            queryClient.setQueryData(keys.status, newCwmEnabledState);
            await invalidateAllQueries();
            onSuccess(newCwmEnabledState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to change Code With Me settings'), data => {
            if (data.status === 'configuration_error') {
                alert.error(data.data.title);
                return null;
            }
            return 'Code With Me settings changed';
        })
    });
}
export function useCwmDisableMutation(onSuccess) {
    return useMutation({
        mutationFn: disableCodeWithMe,
        onSuccess: async (newCwmEnabledState) => {
            queryClient.setQueryData(keys.status, newCwmEnabledState);
            await queryClient.invalidateQueries();
            onSuccess();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to disable Code With Me'), () => 'Code With Me disabled')
    });
}
