// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge__d50 {
  display: inline-flex;
  align-items: center;

  .icon__c54 {
    margin-left: calc(var(--ring-unit) * 0.5);
    display: inline-flex;
    align-items: center;
  }
}

.badge__d50.primary__dd8 {
  background: var(--ring-selected-background-color);
  border-color: var(--ring-selected-background-color);
  color: var(--ring-main-color);
}

.badge__d50.secondary__bb4 {
  background: var(--ring-border-disabled-color);
  border-color: var(--ring-border-disabled-color);
  color: var(--ring-secondary-color);
}

.badge__d50.warning__ffc {
  background: var(--ring-warning-background-color);
  border-color: var(--ring-warning-background-color);
  color: var(--ring-warning-color);
}

.badge__d50.error__d77 {
  background: var(--ring-removed-background-color);
  border-color: var(--ring-removed-background-color);
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/common/badge/specialized-badges/specialized-badges.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;;EAEnB;IACE,yCAAyC;IACzC,oBAAoB;IACpB,mBAAmB;EACrB;AACF;;AAEA;EACE,iDAAiD;EACjD,mDAAmD;EACnD,6BAA6B;AAC/B;;AAEA;EACE,6CAA6C;EAC7C,+CAA+C;EAC/C,kCAAkC;AACpC;;AAEA;EACE,gDAAgD;EAChD,kDAAkD;EAClD,gCAAgC;AAClC;;AAEA;EACE,gDAAgD;EAChD,kDAAkD;EAClD,8BAA8B;AAChC","sourcesContent":[".badge {\n  display: inline-flex;\n  align-items: center;\n\n  .icon {\n    margin-left: calc(var(--ring-unit) * 0.5);\n    display: inline-flex;\n    align-items: center;\n  }\n}\n\n.badge.primary {\n  background: var(--ring-selected-background-color);\n  border-color: var(--ring-selected-background-color);\n  color: var(--ring-main-color);\n}\n\n.badge.secondary {\n  background: var(--ring-border-disabled-color);\n  border-color: var(--ring-border-disabled-color);\n  color: var(--ring-secondary-color);\n}\n\n.badge.warning {\n  background: var(--ring-warning-background-color);\n  border-color: var(--ring-warning-background-color);\n  color: var(--ring-warning-color);\n}\n\n.badge.error {\n  background: var(--ring-removed-background-color);\n  border-color: var(--ring-removed-background-color);\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `badge__d50`,
	"icon": `icon__c54`,
	"primary": `primary__dd8`,
	"secondary": `secondary__bb4`,
	"warning": `warning__ffc`,
	"error": `error__d77`
};
export default ___CSS_LOADER_EXPORT___;
