import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Input, { Size as InputSize } from '@jetbrains/ring-ui/components/input/input';
import { ConfigureModelDialog } from '@modules/ai/common/forms/common/provider-models/model-dialog/configure-model-dialog';
import { HUGGING_FACE_MODEL_CONFIGURATIONS } from '@modules/ai/common/constants';
import { Required } from '@common/form/important-asterisk';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { tHuggingFaceModelSchema } from '../hugging-face-ai-form-schema';
export function HuggingFaceModelConfigurationDialog(props) {
    var _a, _b;
    const { model, type, onCloseAttempt } = props;
    const form = useForm({
        defaultValues: model !== null && model !== void 0 ? model : {
            id: type,
            modelName: type,
            url: '',
            token: undefined
        },
        resolver: zodResolver(tHuggingFaceModelSchema),
        mode: 'onChange'
    });
    const { formState, handleSubmit, watch, setValue } = form;
    const { errors } = formState;
    const handleSave = handleSubmit(onCloseAttempt);
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    return (_jsxs(ConfigureModelDialog, { modelTypeName: HUGGING_FACE_MODEL_CONFIGURATIONS[0].label, onSave: handleSave, onCancel: handleClose, formState: formState, children: [_jsx(Input, { value: watch('id'), label: "ID", size: InputSize.L, disabled: true }), _jsx(Input, { ...assignTestId('url'), value: watch('url'), label: Required('Model URL'), onChange: val => setValue('url', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), size: InputSize.L, error: (_a = errors.url) === null || _a === void 0 ? void 0 : _a.message }), _jsx(Input, { ...assignTestId('token'), label: "Model API token", value: watch('token'), onChange: val => setValue('token', val.target.value ? val.target.value : undefined, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true
                }), placeholder: watch('token') === null ? '•••••••' : '', type: "password", size: InputSize.L, error: (_b = errors.token) === null || _b === void 0 ? void 0 : _b.message })] }));
}
