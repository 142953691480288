import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { zodResolver } from '@hookform/resolvers/zod';
import { Navigate, useParams } from 'react-router-dom';
import { getApplications, getNotification } from '@app/common/store/profiles/selectors';
import { useDialogCancelConfirmation } from '@app/common/hooks/use-dialog-cancel-confirmation';
import { ToolsFilter } from '@modules/applications/tools-filter';
import { Dialog } from '@common/dialog/dialog';
import { useEvaluatedAppFilters } from '@common/queries/profile-evaluations';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { tNotificationssSchema } from '../schemas/notifications-schema';
import { getEmptyNotificationForm } from '../schemas/ctors';
import { NotificationFields } from './notification-fields';
import styles from './notification-dialog-form.css';
export function NotificationForm({ id, onReject, onSubmit }) {
    var _a, _b;
    const { id: profileId } = useParams();
    const notificationData = useSelector(state => getNotification(state, id));
    const form = useForm({
        defaultValues: notificationData !== null && notificationData !== void 0 ? notificationData : getEmptyNotificationForm(),
        resolver: zodResolver(tNotificationssSchema)
    });
    const { formState: { isDirty, isSubmitting, errors }, handleSubmit, watch, setValue } = form;
    const watchFilters = watch('filters');
    const setFilters = (filters) => {
        setValue('filters', filters, {
            shouldDirty: true,
            shouldValidate: true
        });
    };
    const applicationsRules = useSelector(getApplications).rules;
    const { isFetching: isFilterToolsLoading, data: filterTools } = useEvaluatedAppFilters(watchFilters);
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onReject, isDirty);
    if (!profileId) {
        return _jsx(Navigate, { to: "/profiles" });
    }
    return (_jsxs(Dialog, { label: "Rule", show: true, autoFocusFirst: true, onCloseAttempt: onCloseAttempt, size: "wide", ...assignTestId('restrict-ide-usage-dialog'), children: [_jsx(Header, { children: "Restrict IDE Usage" }), _jsx(Content, { children: _jsxs(FormProvider, { ...form, children: [_jsxs("form", { onSubmit: handleSubmit(onSubmit), className: styles.formContainer, children: [_jsx(ToolsFilter, { filters: watchFilters, onFiltersChange: setFilters, target: "restrictions", profileId: profileId, isFilterToolsLoading: isFilterToolsLoading, filterTools: filterTools !== null && filterTools !== void 0 ? filterTools : [], inheritedFilters: applicationsRules }), _jsx(NotificationFields, {})] }), ((_a = errors.filters) === null || _a === void 0 ? void 0 : _a.message) && (_jsx("div", { className: styles.errorMessage, children: ((_b = errors.filters) === null || _b === void 0 ? void 0 : _b.message) && _jsx("p", { children: errors.filters.message }) }))] }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(onSubmit), type: "submit", loader: isSubmitting, disabled: !isDirty, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: onReject, children: "Cancel" })] })] }));
}
