import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import Icon, { Size } from '@jetbrains/ring-ui/components/icon/icon';
import { H4 } from '@jetbrains/ring-ui/components/heading/heading';
import getUID from '@jetbrains/ring-ui/components/global/get-uid';
import okIcon from '@jetbrains/icons/ok';
import failedIcon from '@jetbrains/icons/exception-empty';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './provider-test-connection-result.css';
export function ProviderTestConnectionResult({ testResult }) {
    const messages = useMemo(() => testResult.messages.map(message => ({ ...message, key: getUID('test-connection-message') })), [testResult.messages]);
    return (_jsxs("div", { ...assignTestId('provider-test-connection-result'), children: [testResult.status === 'Error' && (_jsxs("div", { children: [_jsxs("div", { className: classNames(styles.status, styles.statusError), children: [_jsx(Icon, { glyph: failedIcon, size: Size.Size16, color: Icon.Color.RED }), "\u00A0Test failed"] }), _jsx("div", { children: "Please check the provider settings and ensure the configuration is correct" })] })), testResult.status === 'Success' && (_jsxs("div", { children: [_jsxs("div", { className: classNames(styles.status, styles.statusSuccess), children: [_jsx(Icon, { glyph: okIcon, size: Size.Size16, color: Icon.Color.GREEN }), "\u00A0Test successful"] }), _jsx("div", { children: "The connection test is complete. No issues detected in the provider configuration." })] })), _jsx("div", { className: styles.messages, children: messages.map(message => (_jsxs("div", { className: styles.message, children: [message.model && _jsx(H4, { children: message.model.id }), _jsx("div", { children: _jsx("code", { className: styles.messageValue, children: message.value }) })] }, message.key))) })] }));
}
