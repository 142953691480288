import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { ConstituentDialog } from '@modules/constituents/constituent-dialog';
import { IdepTrialContent } from '@modules/ide-provisioner/common/dialogs/idep-settings-dialog/idep-trial-content';
import { IdeProvisionerLogo } from '@modules/ide-provisioner/common/ide-provisioner-logo/ide-provisioner-logo';
import { ConstituentDialogSteps } from '@modules/constituents/constituent-dialog/constituent-dialog-steps';
import { getLimitLockType } from '@modules/constituents/constituent-dialog/limit-assigment-editor/get-limit-lock-type';
import { getTrialMaxUsage } from '@modules/constituents/constituent-dialog/limit-assigment-editor/get-trial-max-usage';
import { useEnableIdePMutation } from '@common/queries/ide-provisioner';
import { useTbeLicense } from '@common/queries/tbe-license';
import { IdePSettingsDialogBase } from './idep-settings-dialog-base';
import styles from './idep-settings-dialog.css';
export function IdePEnableDialog(props) {
    var _a;
    const { onCloseAttempt, startFromStep } = props;
    const { mutate: enable, isLoading } = useEnableIdePMutation(onCloseAttempt);
    const [step, setStep] = useState(startFromStep);
    const { data: licenseData } = useTbeLicense();
    const constituentTrialData = (_a = licenseData === null || licenseData === void 0 ? void 0 : licenseData.constituents.find(c => c.id === 'idep')) === null || _a === void 0 ? void 0 : _a.trial;
    const lockLimitType = getLimitLockType(licenseData === null || licenseData === void 0 ? void 0 : licenseData.plan, constituentTrialData === null || constituentTrialData === void 0 ? void 0 : constituentTrialData.state);
    const maxUsage = getTrialMaxUsage(constituentTrialData);
    const handleStepChange = () => {
        setStep(prev => prev + 1);
    };
    return (_jsxs(ConstituentDialog, { title: "Enable IDE Provisioner", titleLogo: _jsx(IdeProvisionerLogo, {}), children: [step === ConstituentDialogSteps.TRIAL && (_jsx(IdepTrialContent, { constituentData: (constituentTrialData === null || constituentTrialData === void 0 ? void 0 : constituentTrialData.state) === 'AVAILABLE' ? constituentTrialData : undefined, onClose: () => onCloseAttempt(undefined), changeStep: handleStepChange, contentClassName: styles.contentContainer })), step === ConstituentDialogSteps.FORM && (_jsx(IdePSettingsDialogBase, { onActivate: enable, isLoading: isLoading, contentClassName: styles.contentContainer, initialLimitUsage: maxUsage, limitLockType: lockLimitType, ...props }))] }));
}
