// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__a56 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 4);
  margin: calc(var(--ring-unit) * 3) 0;
}

.userDetailsContainer__f7c {
  display: flex;
}

.userInfoContainer__ef5 {
  width: calc(var(--ring-unit) * 50);
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 2);
}

.input__abe > div > input {
  caret-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/regular-user-details-page/regular-user-general-page/regular-user-general-page.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,oCAAoC;AACtC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kCAAkC;EAClC,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 4);\n  margin: calc(var(--ring-unit) * 3) 0;\n}\n\n.userDetailsContainer {\n  display: flex;\n}\n\n.userInfoContainer {\n  width: calc(var(--ring-unit) * 50);\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 2);\n}\n\n.input > div > input {\n  caret-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__a56`,
	"userDetailsContainer": `userDetailsContainer__f7c`,
	"userInfoContainer": `userInfoContainer__ef5`,
	"input": `input__abe`
};
export default ___CSS_LOADER_EXPORT___;
