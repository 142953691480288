export const getEnvironmentOptions = () => {
    return [
        {
            ideMode: 'local',
            label: 'Local',
            id: 'local',
            disabled: false,
            items: []
        },
        {
            ideMode: 'rd',
            label: 'Remote',
            id: 'remote',
            disabled: false,
            items: [
                {
                    ideMode: 'rdClient',
                    label: 'Remote JetBrains Client',
                    id: 'rdClient',
                    disabled: false,
                    items: [],
                    parent: 'rd'
                },
                {
                    ideMode: 'rdBackend',
                    label: 'Remote JetBrains Backend',
                    id: 'rdBackend',
                    disabled: true,
                    items: [],
                    parent: 'rd'
                }
            ]
        }
    ];
};
