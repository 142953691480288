import { jsx as _jsx } from "react/jsx-runtime";
import { useChangeIDEPSettingsMutation } from '@app/common/queries/ide-provisioner';
import { IdeProvisionerLogo } from '@modules/ide-provisioner/common/ide-provisioner-logo/ide-provisioner-logo';
import { ConstituentDialog } from '@modules/constituents/constituent-dialog';
import { IdePSettingsDialogBase } from './idep-settings-dialog-base';
export function IdePChangeSettingsDialog(props) {
    const { onCloseAttempt } = props;
    const { mutate: handleActivate, isLoading } = useChangeIDEPSettingsMutation(onCloseAttempt);
    return (_jsx(ConstituentDialog, { title: "Configure IDE Provisioner", titleLogo: _jsx(IdeProvisionerLogo, {}), children: _jsx(IdePSettingsDialogBase, { ...props, onActivate: handleActivate, isLoading: isLoading }) }));
}
