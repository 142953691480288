import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty, isNil } from 'lodash';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import { zodResolver } from '@hookform/resolvers/zod';
import { replaceProps, replaceQuality, withReplacedSuffix } from '@modules/applications/common/build-filters';
import { useDialogCancelConfirmation } from '@common/hooks/use-dialog-cancel-confirmation';
import RingFieldsList from '@common/form/ring-fields-list';
import { useEvaluatedSettingFilters } from '@common/queries/profile-evaluations';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { tFilterSchema } from './filter-schema';
import TypeField from './fields/type-field';
import ProductField from './fields/product-field';
import BuildField from './fields/build-field/build-field';
import OsField from './fields/os-field';
import ArchField from './fields/arch-field';
import QualityField from './fields/quality-field';
import RemoteEnvironmentField from './fields/remote-environment-field/remote-environment-field';
import styles from './filter-form.css';
const defaultFilter = {
    products: [],
    builds: [],
    type: 'include-all',
    arch: [],
    os: [],
    ideMode: []
};
const optionalProps = ['arch', 'builds', 'products', 'os', 'ideMode'];
export function FilterForm(props) {
    const { item = defaultFilter, allProducts, allQualities, target, inheritedFilters, onCloseAttempt: onCloseAttemptDialog, addQualityField = false, addRemoteEnvironmentField = false, loading } = props;
    const resultItem = replaceQuality(replaceProps(withReplacedSuffix(item), optionalProps));
    const form = useForm({
        defaultValues: resultItem,
        resolver: zodResolver(tFilterSchema)
    });
    const { formState: { isDirty }, handleSubmit } = form;
    const { data: availableProductsRaw } = useEvaluatedSettingFilters(inheritedFilters || [], [], !!inheritedFilters);
    const availableProducts = useMemo(() => availableProductsRaw === null || availableProductsRaw === void 0 ? void 0 : availableProductsRaw.map(({ product, productName }) => ({ id: product, name: productName })), [availableProductsRaw]);
    const submit = (filterValues) => {
        var _a, _b;
        const filterToSubmit = { ...filterValues };
        const allOptionalPropsFlag = optionalProps.every(prop => isEmpty(filterToSubmit[prop]));
        if (filterValues.quality && filterToSubmit.quality) {
            filterToSubmit.quality = {
                name: (_b = (_a = allQualities === null || allQualities === void 0 ? void 0 : allQualities.find(x => { var _a; return x.orderValue === ((_a = filterValues.quality) === null || _a === void 0 ? void 0 : _a.orderValue); })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
                condition: filterValues.type === 'include' ? 'le' : 'gt',
                orderValue: filterValues.quality.orderValue
            };
        }
        // If deleted all the optional props then rule type should include all the products
        // e.x. include becomes include-all
        if (allOptionalPropsFlag && isNil(filterValues.quality)) {
            filterToSubmit.type += '-all';
        }
        onCloseAttemptDialog(filterToSubmit);
    };
    const { onCloseAttempt: onCloseAttemptConfirmation, cancelButtonRef } = useDialogCancelConfirmation(() => onCloseAttemptDialog(undefined), isDirty);
    return (_jsxs(Dialog, { show: true, onCloseAttempt: onCloseAttemptConfirmation, label: "Filter", autoFocusFirst: true, contentClassName: styles.dialogContent, ...assignTestId('single-filter-dialog'), children: [_jsx(Header, { children: "Add Filter" }), _jsx(Content, { children: !loading ? (_jsx(FormProvider, { ...form, children: _jsx("form", { onSubmit: handleSubmit(submit), children: _jsxs(RingFieldsList, { children: [_jsx(TypeField, { target: target }), _jsx(ProductField, { allProducts: allProducts !== null && allProducts !== void 0 ? allProducts : [], availableProducts: availableProducts }), _jsx(BuildField, { allProducts: allProducts !== null && allProducts !== void 0 ? allProducts : [] }), _jsx(OsField, {}), _jsx(ArchField, {}), addQualityField && _jsx(QualityField, { allQualities: allQualities }), addRemoteEnvironmentField && _jsx(RemoteEnvironmentField, {})] }) }) })) : (_jsx(Loader, {})) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(submit), disabled: loading, children: "OK" }), _jsx(Button, { ref: cancelButtonRef, onClick: onCloseAttemptConfirmation, ...assignTestId('ring-dialog-close-button'), children: "Cancel" })] })] }));
}
