import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import { BasicTableView } from '@common/tables/basic-table-view';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { AllocationDateField } from './allocation-date-field';
import styles from './licenses-table.css';
export function LicencesTable({ licenses }) {
    const columns = useMemo(() => [
        {
            id: 'licenseName',
            title: 'License Name',
            getValue: license => (_jsx("span", { className: classNames({ [styles.activeLicense]: !license.to }), children: license.licenseName }))
        },
        {
            id: 'allocation',
            title: 'Allocation (from - to)',
            rightAlign: true,
            getValue: license => _jsx(AllocationDateField, { license: license })
        },
        { id: 'applicationName', title: 'Application' },
        { id: 'hostName', title: 'Hostname' },
        { id: 'ip', title: 'IP' }
    ], []);
    return (_jsx("div", { ...assignTestId('licenses-table'), children: _jsx(BasicTableView, { columns: columns, data: licenses, selectable: false, renderEmpty: () => 'To obtain a license, contact your administrator' }) }));
}
