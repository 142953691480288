import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import classNames from 'classnames';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Icon from '@jetbrains/ring-ui/components/icon';
import newWindowIcon from '@jetbrains/icons/new-window';
import { formatDuration } from 'date-fns';
import { ExternalLinks } from '@common/constants/constants';
import NavigationLink from '@common/navigation/navigation-link';
import { parseDuration } from '@common/dates/format';
import styles from './constituent-trial-activation-dialog-content.css';
export function ConstituentTrialActivationDialogContent({ product, onSkip, onCancel, onActivateTrial, constituentData, contentClassName, limitEntity = 'users' }) {
    const hasLicenseData = (constituentData === null || constituentData === void 0 ? void 0 : constituentData.duration) && constituentData.maxUsage;
    return (_jsxs(_Fragment, { children: [_jsxs(Content, { className: classNames(styles.container, contentClassName), children: [_jsx(H3, { children: "Trial is available" }), hasLicenseData ? (_jsxs(_Fragment, { children: [' ', _jsxs(Text, { children: ["Start your trial now to evaluate ", product, " in your organization with access to up to", ' ', constituentData === null || constituentData === void 0 ? void 0 : constituentData.maxUsage, " ", limitEntity, ' ', (constituentData === null || constituentData === void 0 ? void 0 : constituentData.duration)
                                        ? `for ${formatDuration(parseDuration(constituentData === null || constituentData === void 0 ? void 0 : constituentData.duration))}`
                                        : '', "."] }), _jsxs(Text, { children: ["To increase the number of trial ", limitEntity, ", please contact us."] }), _jsxs(NavigationLink, { href: ExternalLinks.JB_CONTACT_SALES_FORM, target: "_blank", children: ["Contact sales ", _jsx(Icon, { glyph: newWindowIcon })] })] })) : (_jsxs(_Fragment, { children: [_jsxs(Text, { children: ["Your organization has a trial version available for ", product, ". You can request for a trial version or skip it and proceed with standard license setup."] }), _jsxs(Text, { children: ["If you have any questions after enabling trial, please", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_CONTACT_SALES_FORM, target: "_blank", children: "contact us" }), "."] })] }))] }), _jsxs(Panel, { children: [_jsx(Button, { onClick: onActivateTrial, primary: true, "data-test": "activate-trial-button", children: "Start trial" }), _jsx(Button, { onClick: onSkip, "data-test": "skip-trial-button", children: "Skip trial" }), _jsx(Button, { onClick: onCancel, children: "Cancel" })] })] }));
}
