// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannerContainer__d6d {
  display: flex;
  gap: var(--ring-unit);
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: var(--ring-unit) var(--ring-unit) var(--ring-unit) calc(var(--ring-unit) * 2);
  min-height: calc(var(--ring-unit) * 5);
  background-color: var(--ring-sidebar-background-color);
  border-radius: var(--ring-border-radius);
}

.bannerContainer__d6d:before {
  position: absolute;
  display: block;
  left: 0;
  bottom: 0;
  top: 0;
  content: ' ';
  width: var(--ring-border-radius);
  border-bottom-left-radius: var(--ring-border-radius);
  border-top-left-radius: var(--ring-border-radius);
  background-color: var(--ring-main-color);
}

.error__b3b.bannerContainer__d6d:before {
  background-color: var(--ring-icon-error-color);
}

.warning__d31.bannerContainer__d6d:before {
  display: none;
}

.warning__d31.bannerContainer__d6d {
  background-color: var(--ring-warning-background-color);
}

.success__d69.bannerContainer__d6d:before {
  background-color: var(--ring-icon-success-color);
}

.neutral__c9e.bannerContainer__d6d:before {
  display: none;
}

.neutral__c9e {
  background-color: var(--ring-hover-background-color);
}

.danger__fae {
  &.bannerContainer__d6d:before {
    display: none;
  }

  background: var(--ring-removed-background-color);
}
`, "",{"version":3,"sources":["webpack://./src/common/information-banner/information-banner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,kBAAkB;EAClB,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,sFAAsF;EACtF,sCAAsC;EACtC,sDAAsD;EACtD,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,OAAO;EACP,SAAS;EACT,MAAM;EACN,YAAY;EACZ,gCAAgC;EAChC,oDAAoD;EACpD,iDAAiD;EACjD,wCAAwC;AAC1C;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sDAAsD;AACxD;;AAEA;EACE,gDAAgD;AAClD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE;IACE,aAAa;EACf;;EAEA,gDAAgD;AAClD","sourcesContent":[".bannerContainer {\n  display: flex;\n  gap: var(--ring-unit);\n  position: relative;\n  box-sizing: border-box;\n  justify-content: space-between;\n  align-items: center;\n  padding: var(--ring-unit) var(--ring-unit) var(--ring-unit) calc(var(--ring-unit) * 2);\n  min-height: calc(var(--ring-unit) * 5);\n  background-color: var(--ring-sidebar-background-color);\n  border-radius: var(--ring-border-radius);\n}\n\n.bannerContainer:before {\n  position: absolute;\n  display: block;\n  left: 0;\n  bottom: 0;\n  top: 0;\n  content: ' ';\n  width: var(--ring-border-radius);\n  border-bottom-left-radius: var(--ring-border-radius);\n  border-top-left-radius: var(--ring-border-radius);\n  background-color: var(--ring-main-color);\n}\n\n.error.bannerContainer:before {\n  background-color: var(--ring-icon-error-color);\n}\n\n.warning.bannerContainer:before {\n  display: none;\n}\n\n.warning.bannerContainer {\n  background-color: var(--ring-warning-background-color);\n}\n\n.success.bannerContainer:before {\n  background-color: var(--ring-icon-success-color);\n}\n\n.neutral.bannerContainer:before {\n  display: none;\n}\n\n.neutral {\n  background-color: var(--ring-hover-background-color);\n}\n\n.danger {\n  &.bannerContainer:before {\n    display: none;\n  }\n\n  background: var(--ring-removed-background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `bannerContainer__d6d`,
	"error": `error__b3b`,
	"warning": `warning__d31`,
	"success": `success__d69`,
	"neutral": `neutral__c9e`,
	"danger": `danger__fae`
};
export default ___CSS_LOADER_EXPORT___;
