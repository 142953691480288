export function getIdeModeName(mode) {
    switch (mode) {
        case 'local':
            return 'Local';
        case 'rd':
            return 'Remote';
        case 'rdClient':
            return 'Remote JetBrains Client';
        case 'rdBackend':
            return 'Remote JetBrains Backend';
        default:
            return mode;
    }
}
