import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import userIcon from '@jetbrains/icons/user';
import { isNil } from 'lodash';
import { THIS_MONTH_TIMEFRAME } from '@api/dashboard';
import { ConstituentEnabledWidget } from '@modules/constituents/widget/constituent-enabled-widget';
import { AI_PROVIDERS } from '@modules/ai/common/constants';
import { useConstituentCurrentUsage, useConstituentUsagePerDays } from '@common/queries/dashboard';
import NavigationLink from '@common/navigation/navigation-link';
import { IntervalStatisticsWidgetCounter, StatisticsWidgetCounter } from '@common/widgets/statistics-widget/statistics-widget-counter';
import { StatisticsWidgetTimeframeSelector } from '@common/widgets/statistics-widget/statistics-widget-timeframe-selector';
import { StatisticsWidgetBarChart } from '@common/widgets/statistics-widget/statistics-widget-bar-chart';
import { StatisticsWidgetInfoStats, StatisticsWidgetInfoStatsSize } from '@common/widgets/statistics-widget/statistics-widget-info-stats';
import { useAiConstituent } from '@common/queries/ai-enterprise';
import { JustifyContent } from '@common/widgets/widget/widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
export function AiActiveWidget(props) {
    var _a, _b;
    const constituent = { id: 'ai', title: 'AI Enterprise' };
    const [timeframe, setTimeframe] = useState(THIS_MONTH_TIMEFRAME);
    const { data: constituentState, isLoading: isStateLoading } = useAiConstituent();
    const { data, isLoading } = useConstituentUsagePerDays(constituent, timeframe);
    const { data: currentUsage, isLoading: isCurrentUsageLoading } = useConstituentCurrentUsage(constituent);
    const [activeInterval, setActiveInterval] = useState();
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.days.length) {
            setActiveInterval(data.days[data.days.length - 1] || undefined);
        }
    }, [data]);
    const userLimit = (constituentState === null || constituentState === void 0 ? void 0 : constituentState.status) === 'enabled' ? constituentState === null || constituentState === void 0 ? void 0 : constituentState.data.assignedUsersLimit : undefined;
    return (_jsxs(ConstituentEnabledWidget, { ...props, ...assignTestId('widget-ai-enterprise'), loading: isLoading || isCurrentUsageLoading || isStateLoading, justifyContent: JustifyContent.Bottom, settingsLink: "/configuration/license-activation/ai-settings", children: [_jsx(StatisticsWidgetInfoStats, { statName: "Current users", size: StatisticsWidgetInfoStatsSize.TwoColumns, statValue: _jsxs(NavigationLink, { href: "/users?constituentId=ai", children: [_jsx("span", { ...assignTestId('usage'), children: (_a = currentUsage === null || currentUsage === void 0 ? void 0 : currentUsage.value) !== null && _a !== void 0 ? _a : 0 }), !isNil(userLimit) && (_jsxs("span", { children: [' ', "/ ", _jsx("span", { ...assignTestId('limit'), children: userLimit })] }))] }), ...assignTestId('usage-count') }), (constituentState === null || constituentState === void 0 ? void 0 : constituentState.status) === 'enabled' && (_jsx(AiProviderStat, { provider: constituentState.data.defaultProvider })), activeInterval && (data === null || data === void 0 ? void 0 : data.days) && (_jsxs(_Fragment, { children: [_jsx(StatisticsWidgetBarChart, { intervals: data === null || data === void 0 ? void 0 : data.days, activeInterval: activeInterval, onActivateInterval: setActiveInterval }), _jsx(StatisticsWidgetTimeframeSelector, { timeframe: timeframe, onSelect: setTimeframe, label: "Daily usages" }), _jsx(IntervalStatisticsWidgetCounter, { icon: userIcon, count: (_b = activeInterval === null || activeInterval === void 0 ? void 0 : activeInterval.total) !== null && _b !== void 0 ? _b : 0, measure: "Maximum daily usages", activeInterval: activeInterval }), data.maxUsageDay && (_jsx(StatisticsWidgetCounter, { icon: userIcon, count: data.maxUsageDay.total, label: "Maximum daily usages for the period" }))] }))] }));
}
function AiProviderStat(props) {
    var _a;
    const { provider } = props;
    const providerName = (_a = AI_PROVIDERS[provider]) === null || _a === void 0 ? void 0 : _a.label;
    return (_jsx(StatisticsWidgetInfoStats, { statName: "Provider", statValue: providerName, size: StatisticsWidgetInfoStatsSize.TwoColumns, ...assignTestId(`${{ provider }}-ai-provider`) }));
}
