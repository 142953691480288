import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { getConstituentDialogFirstStep } from '@modules/constituents/constituent-dialog/constituent-dialog-steps';
import { useLvConstituentState } from '@common/queries/license-vault';
import { useModalsController, useOpenModal } from '@common/modals/hooks';
import { LvEnableDialog } from '../../common/dialogs/lv-enable-dialog/lv-enable-dialog';
import { LvDisableDialog } from '../../common/dialogs/lv-disable-dialog/lv-disable-dialog';
import { LicenseVaultLimits } from '../../common/dialogs/license-vault-limits/license-vault-limits';
import { LicenseVaultDisabled } from './license-vault-disabled/license-vault-disabled';
import { LicenseVaultEnabled } from './license-vault-enabled/license-vault-enabled';
export function LicenseVaultConstituent(props) {
    const { className, licenseType, constituentLicense } = props;
    const modalsController = useModalsController();
    const { data } = useLvConstituentState();
    const openDisableDialog = useOpenModal(LvDisableDialog, {});
    const handleOpenModal = useCallback(async () => {
        const openEnableModal = modalsController.openModal(LvEnableDialog, {
            startFromStep: getConstituentDialogFirstStep(constituentLicense, licenseType)
        });
        openEnableModal.promise.then(() => {
            openEnableModal.closeModal();
        });
    }, [constituentLicense, licenseType, modalsController]);
    const openLicensesDialog = useOpenModal(LicenseVaultLimits, {
        isTrialActive: constituentLicense.trial.state === 'ACTIVE'
    });
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled') {
        return (_jsx(LicenseVaultDisabled, { className: className, onEnabledClick: handleOpenModal, trialData: { licenseType, license: constituentLicense } }));
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'enabled') {
        return (_jsx(LicenseVaultEnabled, { className: className, onDisableClick: openDisableDialog, onOpenLicensesDetails: openLicensesDialog, licenseType: licenseType, license: constituentLicense }));
    }
    return null;
}
