// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloadsContainer__af0 {
  display: flex;
  align-items: center;
  gap: var(--ring-unit);
}

.downloadsChartContainer__b8b {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: calc(var(--ring-unit) * 3) 0 var(--ring-unit) 0;
}

.downloadsWeekChart__a29 {
  width: calc(var(--ring-unit) * 40);
}

.statsLink__ec4 {
  margin-left: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/plugins/plugin-page/plugin-info/plugin-downloads-info.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,uDAAuD;AACzD;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".downloadsContainer {\n  display: flex;\n  align-items: center;\n  gap: var(--ring-unit);\n}\n\n.downloadsChartContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  margin: calc(var(--ring-unit) * 3) 0 var(--ring-unit) 0;\n}\n\n.downloadsWeekChart {\n  width: calc(var(--ring-unit) * 40);\n}\n\n.statsLink {\n  margin-left: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadsContainer": `downloadsContainer__af0`,
	"downloadsChartContainer": `downloadsChartContainer__b8b`,
	"downloadsWeekChart": `downloadsWeekChart__a29`,
	"statsLink": `statsLink__ec4`
};
export default ___CSS_LOADER_EXPORT___;
