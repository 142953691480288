// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button__f98 {
  margin: 0 calc(-1 * var(--ring-unit));
}

.iconActive__ddd svg {
  color: var(--ring-main-color);
  fill: var(--ring-main-color);
}

.iconSuccess__e7f svg {
  color: var(--ring-success-color);
  fill: var(--ring-success-color);
}

.iconError__c58 svg {
  color: var(--ring-error-color);
  fill: var(--ring-error-color);
}

.message__b0f:not(:last-child) {
  margin-bottom: calc(var(--ring-unit) * 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-settings-form/common/provider-test-connection-button.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;EACE,gCAAgC;EAChC,+BAA+B;AACjC;;AAEA;EACE,8BAA8B;EAC9B,6BAA6B;AAC/B;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".button {\n  margin: 0 calc(-1 * var(--ring-unit));\n}\n\n.iconActive svg {\n  color: var(--ring-main-color);\n  fill: var(--ring-main-color);\n}\n\n.iconSuccess svg {\n  color: var(--ring-success-color);\n  fill: var(--ring-success-color);\n}\n\n.iconError svg {\n  color: var(--ring-error-color);\n  fill: var(--ring-error-color);\n}\n\n.message:not(:last-child) {\n  margin-bottom: calc(var(--ring-unit) * 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button__f98`,
	"iconActive": `iconActive__ddd`,
	"iconSuccess": `iconSuccess__e7f`,
	"iconError": `iconError__c58`,
	"message": `message__b0f`
};
export default ___CSS_LOADER_EXPORT___;
