import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet, useLoaderData } from 'react-router-dom';
import { RouteTab, RouteTabs } from '@common/route-tabs';
import { PageTitle } from '@common/page-layout/page-title';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useLvConstituentState } from '@common/queries/license-vault';
export function RegularUserDetailsPage() {
    const user = useLoaderData();
    const { data: lvConstituentState, isLoading: isLVStateLoading } = useLvConstituentState();
    const licenseVaultTabVisible = !isLVStateLoading && (lvConstituentState === null || lvConstituentState === void 0 ? void 0 : lvConstituentState.status) === 'enabled';
    return (_jsxs("div", { ...assignTestId('user-details-page-content'), children: [_jsx(PageTitle, { title: user.username }), _jsxs(RouteTabs, { ...assignTestId('user-details-page-tabs'), children: [_jsx(RouteTab, { path: "", title: "General", titleProps: assignTestId('general') }), _jsx(RouteTab, { path: "aggregated-settings", title: "Aggregated Settings", titleProps: assignTestId('aggregated-settings') }), licenseVaultTabVisible && (_jsx(RouteTab, { path: "licenses", title: "Licenses", titleProps: assignTestId('licenses') }))] }), _jsx(Outlet, {})] }));
}
