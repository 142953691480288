import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import HorizontalContainer from '@app/common/page-layout/horizontal-container';
import SearchFilter from '@app/common/search-filter/search-filter';
import styles from './plugin-version-filters.css';
export default function PluginVersionFilters({ loading, params, onParamsChange }) {
    const handleSearchValueChange = useCallback((newValue) => {
        onParamsChange({
            ...params,
            searchValue: newValue,
            page: newValue !== params.searchValue ? 1 : params.page
        });
    }, [onParamsChange, params]);
    return (_jsxs(HorizontalContainer, { className: styles.filtersContainer, children: [_jsx(SearchFilter, { value: params.searchValue, onChange: handleSearchValueChange, placeholder: "Search by plugin version", size: Size.L }), loading && (_jsx("div", { className: styles.fetchingLoader, children: _jsx(LoaderInline, {}) }))] }));
}
