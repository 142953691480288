import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { ConstituentDisabledWidget } from '@modules/constituents/widget/constituent-disabled-widget';
import { getConstituentDialogFirstStep } from '@modules/constituents/constituent-dialog/constituent-dialog-steps';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useModalsController } from '@common/modals/hooks';
import { CwmEnableDialog } from '../../../common/dialogs/cwm-enable-dialog/cwm-enable-dialog';
export function CwmDisabledTrialActiveWidget(props) {
    const { constituentLicense, licenseType } = props;
    const modalsController = useModalsController();
    const handleOpenModal = useCallback(async () => {
        const openEnableModal = modalsController.openModal(CwmEnableDialog, {
            startFromStep: getConstituentDialogFirstStep(constituentLicense, licenseType)
        });
        openEnableModal.promise.then(() => {
            openEnableModal.closeModal();
        });
    }, [constituentLicense, licenseType, modalsController]);
    return (_jsx(ConstituentDisabledWidget, { ...props, ...assignTestId('widget-code-with-me'), onEnableClick: handleOpenModal, children: _jsx(Text, { children: "Your trial is ready. Enable and configure Code With Me to start using its features in your organization." }) }));
}
