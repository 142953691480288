// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__a96 {
  .contentWrapper__fa2 {
    margin-top: var(--ring-unit);
  }

  .contentInner__d6c {
    display: flex;
    flex-direction: column;
    gap: calc(var(--ring-unit) * 3);
  }

  .actions__d26 {
    margin-top: var(--ring-unit);
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/common/forms/common/provider-models/model-dialog/configure-model-dialog.css"],"names":[],"mappings":"AAAA;EACE;IACE,4BAA4B;EAC9B;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,+BAA+B;EACjC;;EAEA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".wrapper {\n  .contentWrapper {\n    margin-top: var(--ring-unit);\n  }\n\n  .contentInner {\n    display: flex;\n    flex-direction: column;\n    gap: calc(var(--ring-unit) * 3);\n  }\n\n  .actions {\n    margin-top: var(--ring-unit);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__a96`,
	"contentWrapper": `contentWrapper__fa2`,
	"contentInner": `contentInner__d6c`,
	"actions": `actions__d26`
};
export default ___CSS_LOADER_EXPORT___;
