import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDialogCancelConfirmation } from '@app/common/hooks/use-dialog-cancel-confirmation';
import { ToolsFilter } from '@modules/applications/tools-filter';
import { Dialog } from '@common/dialog/dialog';
import { useEvaluatedSettingFilters } from '@common/queries/profile-evaluations';
import { getApplications } from '@common/store/profiles/selectors';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import auth from '@common/auth/auth';
import { tDisabledPluginsSchema } from '../../schemas/disabled-plugins-schema';
import { getEmptyDisabledPluginsFormValues } from '../../schemas/ctors';
import { useUserPluginRestrictedMode } from '../../hooks/user-restricted-modes';
import { PluginFieldArray } from './plugin-field-array';
import styles from './disabled-plugins-dialog.css';
export function DisabledPluginsDialogForm({ item, onCloseAttempt: onFinishDialogAttempt // maybe it is a better to introduce new name ?
 }) {
    var _a, _b;
    const { id: profileId } = useParams();
    const { remoteDevelopmentDisabledPluginsUi } = auth.getFeatures();
    const { isRestricted, restrictedPlugins } = useUserPluginRestrictedMode();
    const form = useForm({
        defaultValues: item !== null && item !== void 0 ? item : getEmptyDisabledPluginsFormValues(),
        resolver: zodResolver(tDisabledPluginsSchema(isRestricted, restrictedPlugins))
    });
    const { formState: { isDirty, isSubmitting, errors }, handleSubmit, setValue, watch } = form;
    const handleCancel = useCallback(() => onFinishDialogAttempt(undefined), [onFinishDialogAttempt]);
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(handleCancel, isDirty);
    const watchFilters = watch('filters');
    const setFilters = (filters) => {
        setValue('filters', filters, {
            shouldDirty: true,
            shouldValidate: true
        });
    };
    const applications = useSelector(getApplications);
    const { isFetching: isFilterToolsLoading, data: filterTools } = useEvaluatedSettingFilters(applications.rules, watchFilters);
    const canSubmit = isDirty && !isFilterToolsLoading;
    const submit = (formData) => {
        onFinishDialogAttempt({ ...formData, tools: filterTools !== null && filterTools !== void 0 ? filterTools : [] });
    };
    if (!profileId) {
        return _jsx(Navigate, { to: "/profiles" });
    }
    return (_jsxs(Dialog, { show: true, autoFocusFirst: false, onCloseAttempt: onCloseAttempt, size: "wide", ...assignTestId('disable-plugins-dialog'), children: [_jsx(Header, { children: "Disable Plugins" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(submit), className: styles.formContainer, children: [_jsx(ToolsFilter, { filters: watchFilters, onFiltersChange: setFilters, target: "settings", disabledWithMessage: isRestricted ? 'Filter modification is disallowed in Restricted Mode' : undefined, profileId: profileId, isFilterToolsLoading: isFilterToolsLoading, filterTools: filterTools !== null && filterTools !== void 0 ? filterTools : [], inheritedFilters: applications.rules, addRemoteEnvironmentField: !!remoteDevelopmentDisabledPluginsUi }), _jsx(PluginFieldArray, {}), ((_a = errors.filters) === null || _a === void 0 ? void 0 : _a.message) && (_jsx("div", { className: styles.errorMessage, children: ((_b = errors.filters) === null || _b === void 0 ? void 0 : _b.message) && _jsx("p", { children: errors.filters.message }) }))] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(submit), type: "submit", loader: isSubmitting, disabled: !canSubmit, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: handleCancel, ...assignTestId('cancel-button'), children: "Cancel" })] })] }));
}
